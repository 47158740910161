import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import SLM from '../assets/img/SLM.png';
import GLM from '../assets/img/GLM.png';
class AboutPage extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div style={{ textAlign: 'center', padding: 40 }}>
          <h1>Connected Crossing</h1>
          <label>
            TekTracking’s Connected Crossing contains multiple diverse sensors
            to measure, compare, and verify all aspects of proper crossing
            operation. Working with a Class One Railroad we have developed a
            product suite to monitor proper operation of the grade crossing’s
            electronic controller, relays, track circuits and the view of the
            crossing gates and warning lights from motorists’ point of view.
          </label>

          <h1>External Crossing Sensors (Outside Signal Bungalow)</h1>
          <div style={{ padding: 20 }}>
            <h2>Safety Light Monitor (SLM)</h2>
            <img src={SLM} height={300} width={500} />
            <label style={{ padding: 10 }}>
              The SLM is a multi-sensor device designed to monitor the integrity
              of a safety light from the perspective of an external viewer of
              the light. The SLM is equipped with five sensors specifically
              chosen to remotely mimic the periodic inspection of safety lights.
              This device mounts under the sun shield of any signal or crossing
              warning light.
            </label>
          </div>
          <div>
            <h2>Gate Light Monitor (GLM) </h2>
            <img src={GLM} height={300} width={500} />
            <label style={{ padding: 10 }}>
              The GLM mounts on the crossing gate pole and monitors the gate
              position while in transition. Once the gate is activated, the GLM
              automatically detects the two flashing lights and the single
              steady light on the gates. If any one of the lights does not
              operate per specification, the GLM will generate an alarm message
              to the signal maintainer. Multiple GLM video feeds can be stitched
              together to achieve a 360° view.
            </label>
          </div>
          <h1>Gate Light Monitor Demo</h1>
          <ReactPlayer
            width={'100%'}
            style={{ alignSelf: 'center', marginBottom: 2 }}
            url={'https://youtu.be/BwKER0eCvC8'}
            controls={true}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default AboutPage;
