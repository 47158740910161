import React from "react";
import PropTypes from "prop-types";
import TablePagination from "./TablePagination";
import { Table } from 'reactstrap';

const DetectionStatusTable = ({
  detectionsStatus,
  currentPage,
  pagesCount,
  handlePageClick,
  handlePreviousClick,
  handleNextClick,
  pageSize
}) => {
  return (
    <React.Fragment>
      <Table striped bordered>
        <thead>
          <tr>
            
            <th>#</th>
            <th>Flash Count  At Node 1</th>
            <th>Flash Count At Node 2</th>
            <th>Date/Time</th>
          </tr>
        </thead>
        <tbody>
          {detectionsStatus.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
            .map((detection => {
            return (
                

              <tr key={detection.dateTime}>
              
              <th scope="row">{detection.srno} </th>
              
              <td>
              {detection.Node1BlinkCount}
              </td>
              <td>
              {detection.Node2BlinkCount}
              </td>
              <td>
              {detection.dateTime.toString("Z")}
              </td>
              </tr>
            );
          }))}
        </tbody>
      </Table>
      <TablePagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        handlePageClick={handlePageClick}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
      />
    </React.Fragment>
  );
};

DetectionStatusTable.propTypes = {
  detectionsStatus: PropTypes.array.isRequired,
  pagesCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  handlePreviousClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func.isRequired
};

export default DetectionStatusTable;