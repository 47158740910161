  
  import React from 'react';
  import PropTypes from '../utils/propTypes';
  import { timer } from 'rxjs';
  import {Button} from 'reactstrap';
  
  const styles = {
    center: {
      marginLeft: "7%",
      marginRight: "auto",
      marginTop:"7%",
      marginBottom: "auto",
    }
  }
  
class LightNode extends React.Component {

  
  
constructor()
{

    super();
    this.state=
    {
    fillColor: "#550000",
    maxBlinkCount:0 
    }
  
    

       this.BlinkLight= this.BlinkLight.bind(this);

}
componentDidMount()
{
  if(this.props.node.NodeMacAddress)
  {
    this.setState({NodeMacAddress:this.props.node.NodeMacAddress})

  }

    if (this.props.blinkCount>0)
    {

    this.setState({maxBlinkCount:this.props.blinkCount})
    this.numbers= timer(1000, 500);
    this.subscription =this.numbers.subscribe(x => 
        {
            console.log(x)
            this.BlinkLight(x);
     
        });

    }






}
componentDidUpdate(prevProps)
{
    if (this.props.blinkCount!==prevProps.blinkCount)
    {

    this.setState({maxBlinkCount:this.props.blinkCount})
    this.numbers= timer(1000, 500);
    this.subscription =this.numbers.subscribe(x => 
        {
            console.log(x)
            this.BlinkLight(x);
     
        });

    }




}


BlinkLight(x)
{
if(x >= (this.state.maxBlinkCount*2))
{
console.log('Stopped Light Blink');
this.subscription.unsubscribe();
this.setState({fillColor: "#550000"});
return;
}
if(x%2===0)
{
        this.setState({fillColor: "#ff0000"});
}
else
{
    this.setState({fillColor: "#550000"});
}



}
 
  
  render(){
 return ( 
    <svg xmlns="http://www.w3.org/2000/svg" width="280" height="320"  style={styles.center} >
 
  
  
  <g stroke="null">
    <path d="M-1-1h256v265H-1V-1z" />
    <text
    x="10.808"
    y="19.255"
    stroke="#ffffff"
    
    transform="matrix(1.6594 0 0 1.64528 .66 -1.976)"
  >
    {this.state.maxBlinkCount}
  </text>
    <g fill={this.state.fillColor}>
      <ellipse
        cx="128.114"
        cy="128.318"
        stroke="#f7f7f7"
        rx="107.031"
        ry="106.12"
      />
     
      { this.props.faulty &&
      <text
        x="66.388"
        y="102.257"
        stroke="#faa"
        transform="matrix(1.6594 0 0 1.64528 .66 -1.976)"
        style={{fontSize: '7vh',    fill: 'white'}}
      >
        !
      </text>
      }
      <text
      x="15.388"
      y="280.257"
      stroke="#000000"
      
    >
    {this.props.index+1} : {this.state.NodeMacAddress}
    
  
   </text>
    </g>
  </g>
</svg>

  
  )
  }
}

export default LightNode;