import React, { useState,useRef,useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import signallogoimage from '../assets/img/cpsignal.png';
import DeviceServices from '../service/DeviceService';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import CustomCheckbox from "./CustomCheckBox";
import * as moment from 'moment';


import {
  Button,
  InputGroup,InputGroupAddon,
  Card, CardBody,CardImg,
  CardHeader,
  Col, Form, FormGroup, Input,
  Label,
  Row,Modal, ModalFooter,
  ModalHeader, ModalBody
} from 'reactstrap';
import Page from '../components/Page';
import CheckBox from '../components/CheckBox';
import classNames from 'classnames';
 import SignaturePad from 'react-signature-canvas' 


let TektrackingLogo =require ('../assets/img/Tektrackinglogo.png');


function buildUrl(url, parameters) {
  let qs = '';
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = parameters[key];
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + '?' + qs;
  }

  return url;
}
const PostData = async (formdata) => {
  try {
    const response = await fetch(
      buildUrl('/api/inspections', {
        
      }),
      {
        method: 'Post', // or 'PUT'
        mode: 'cors',
        //body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
        },
        body:JSON.stringify(formdata),
      },
    );
    const json = await response.json();
    console.log(json);    
  } catch (error) {
    console.log('error', error);
  }
};

let inspectiondata=[{
    CardHeading: "",
    InspectionItems: [
     
      {
        label: "Power Off Light (GCWS T&I 2.2) Weekly",
        type: "checkbox",
        name: "option1",
        id: "option1",
        place: "",
        value:false
      },
      {
        label: "Light Units -- Physical Condition (GCWS T&I 2.2) Weekly",
        type: "checkbox",
        name: "option2",
        id: "option2",
        place: "",
        value:false
      },
      {
        label: "Light Unit Alignment (GCWS T&I 2.2) Weekly",
        type: "checkbox",
        name: "option3",
        id: "option3",
        place: "",
        value:false
      },
      {
        label: "Bell Operation (GCWS T&I 2.2) Weekly ",
        type: "checkbox",
        name: "option4",
        id: "option4",
        place: "",
        value:false
      },  {
        label: "Light Unit Operation (GCWS T&I 2.2) Weekly ",
        type: "checkbox",
        name: "option5",
        id: "option5",
        place: "",
        value:false
      }, {
        label: "Gate Operation (GCWS T&I 2.2) Weekly ",
        type: "checkbox",
        name: "option6",
        id: "option6",
        place: "",
        value:false
      },
      // {
      //   label: "Inspection Remarks",
      //   type: "textarea",
      //   name: "InspectionRemarks",
      //   id: "InspectionRemarks"
      // },
    ]
  }];

  const $quoteSym = '"';
  const useStyles = createUseStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      maxHeight: 50,
      '& li': {
        width: 100
      }
    },
    wrapper: {
      borderTop: 'gray solid 1px',
      borderBottom: 'gray solid 21px',
      display: 'flex',
      padding: 0,
    },
    checkboxs: {
  
      position: "relative",
      marginTop: "0"
  
    },
  
    lableWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "6px 0px"
    },
    lable: {
      margin: "0"
    },
    clable: {
      margin: "10",
      fontSize:"18px"

    },
    card: {
      border: "1px"
    },
    cardHeader: {
      border: "0px",
  
  
    },
    hr: {
      margin: "10px 0,0,0",
      border: "none",
      height: "1px",
      color: "#333",
      backgroundColor: "grey"
  
    },

    gapbottom: {
      margin:"1.25rem",
      
      
  
    },
    cardBody: {
      padding: "0 1.25rem 0 1.25rem"
    },
    rowWrapper: {
      display: "flex",
      alignItems: "center"
    }
    ,
    textAreaWrapper: {
      marginTop: "10px"
    },
    textArea:{
      border:"1px solid grey",
      borderRadius:"0",
      resize: "none",
      '&:focus': {
        outline: "none !important",
      boxShadow: "0"
      }}
,
signaturearea:{
      
   maxWidth: '100%', objectFit: 'scale-down' ,        
        border: "1px solid #808080",
        background: "#ffffff",
        backgroundBlendMode:'normal'
        
      }
    ,
    signinspectionbutton:
    {
      margin:"20px"
    },
    keymaster:
    {
        maxWidth: '100%', objectFit: 'scale-down' ,        
        border: "1px solid #808080",
        background: "#ffffff",
        backgroundBlendMode:'normal',
        padding: "1.25rem 1.25rem 1.25rem 1.25rem",
        
      
    },
    keywithborder:
    {
        maxWidth: '100%', objectFit: 'scale-down' ,        
        border: "0px solid #808080",
        
        backgroundBlendMode:'normal',
        padding: "1.25rem 1.25rem 2.25rem 1.25rem",
        marginLeft: "auto",
        marginRight: "auto",
        
    }

      
    
  
  
  
  });
  
  
function CreateCheckControlV2(type,value,id,label,handleChange,isChecked,classes) {
    
  if(type=="checkbox")
  {
 return (
  <>
          <Row>
                                  <Col className={classes.lableWrapper} >


                                    <Label for={id} className={classes.lable}>
                                      {label}
                                    </Label>

                                   
    <FormGroup check className="form-checkbox">
 <CustomCheckbox id={id} value={value} onChange={handleChange}></CustomCheckbox>
 </FormGroup>
                                  </Col>
                                </Row>


                              </>
 )  
  
}else if(type=="textarea")
{
  return(
    <>
    
    
        <div class={classes.textAreaWrapper}>
       


<Label for={id} className={classes.clable} >
{label}
</Label>
   <Input id={id} type={type} onChange={handleChange}   rows="10"
          className={classes.textArea}></Input>
           </div>
    
  </>
 
  )

}
}

  
  


        
  const InspectionPageV2 = (props) => {
    const [count, setCount] = useState(0);



   

    
    console.log(props);
    let MacAddress=null;
    if(!props.MACAddress){
    MacAddress = props.location.state.MacAddress;
    }else 
    {
      MacAddress =props.MACAddress;

    }
    var device = DeviceServices.getDevice(MacAddress);

   // let crossingInfo = device.CrossingInfo;
   const data = window.localStorage.getItem(MacAddress);
   let initialState = {};
   initialState.inspectionDate =moment(new Date()).format('YYYY-MM-DD');
   if (data) {
      initialState=JSON.parse(data);
   }

    let [inputs, setInputs] = useState(initialState)                  ;
      

    

    
    
const [selectedValue, setSelectedValue] = useState("");

const handleValueChange = (id,value) => {
  console.log(id+ ':'+value);
};
    
    const [submitting, setSubmitting] = useState(false);
    
    const [imageURL, setImageURL] = useState(null); // create a state that will contain our image url
    // Modal open state
    const [modal, setModal] = React.useState(false);
  
    // Toggle for Modal
    const toggle = () => setModal(!modal);
   

    const sigCanvas = useRef({});
    const clear = () => {sigCanvas.current.clear();
      setImageURL(null);
    }
    const save = () =>
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));

  
    const handleChange = (event) => {
      const name = event.target.id;
      var value = "";
      if (event.target.type === "checkbox") {
        value = event.target.checked ? "true" : "false";
      }
      else if(event.target.type === "date"){
        
          value = event.target.value;
        
      }
      else {
        
        
        value = event.target.value;
        value=  value.replace( /[^a-zA-Z0-9  .]/gm, '');
      }
      setInputs(values => ({ ...values, [name]: value }));
      window.localStorage.setItem(MacAddress, JSON.stringify({ ...inputs, [name]: value }));
      if(imageURL===null) {
      let sigimg=window.localStorage.getItem(inputs['User']);
      if(sigimg)
      {       
        setImageURL(sigimg);
       }
      };

    }

    var isChecked = (item) =>{
    //return true if item is in inputs list
      console.log(inputs);
      
      let result=Object.keys(inputs);
     return result.includes(item) ? "checked-item" : "not-checked-item";

    }
    const handleSubmit = event => {
  
  
      // alert('You have submitted the form.')
      event.preventDefault();
      
      //Validate All required Inputs are filled.

      if(inputs['inspectionDate']===undefined)
      {
       window.alert("Inspection Date is required.");
      return;
      }
      if(inputs['InspectionRemarks']===undefined)
      {
       window.alert("Inspection Remarks is required.");
      return;
      }

      

      if(inputs['User']===undefined)
      {
       window.alert("Signed By Name is required.");
       return;

      }
      if(imageURL===null)
      {
       window.alert("Signature is required for submission. Please 'use sign inspection to save  signature'");
       return;

      }


      // Create Inspection Form Object from the Valid information

      let InspectionObject = {};

      InspectionObject.Crossing_Name = device.Name ? device.Name : "N/A";
      InspectionObject.CrossingInfo = device.CrossingInfo? device.CrossingInfo:
      {
       "Name": "N/A",
       "AssetID": "N/A",
       "Division": "N/A",
       "SubDivision": "N/A",
       "MilePost": "N/A",
       "Location": "N/A",
       "Province": "N/A"
     };
     ;
      InspectionObject.EIP_MAC =MacAddress;
      InspectionObject.Test_Date = inputs['inspectionDate'];
      InspectionObject.InspectionType="WarningSystem_Weekly";
      InspectionObject.Inspection_Template =JSON.stringify(inspectiondata);
      InspectionObject.InspectedBy =inputs['User'];
      InspectionObject.Inspection_signature = imageURL;
      InspectionObject.Inspection_Results = JSON.stringify(inputs);

    window.localStorage.setItem(inputs['User'],imageURL);


      PostData(InspectionObject);

      //alert(Object.values(inputs));
      alert("Inspection Submitted Successfully");
      setSubmitting(true);
      setTimeout(() => {
        setSubmitting(false);
      }, 10000);
    }
    device.CrossingInfo = device.CrossingInfo? device.CrossingInfo :  {
      "Name": "N/A",
      "AssetID": "N/A",
      "Division": "N/A",
      "SubDivision": "N/A",
      "MilePost": "N/A",
      "Location": "N/A",
      "Province": "N/A"
    };
    const classes = useStyles();
    return (
      
      <Page>
       
       

        {submitting && <div>  Inspection is being submitted.
        </div>
        }
     
        
      
         
         
              
          
              <Row>
              <Col  xl={2} lg={2} md={1}>
        <img
      alt="Card image cap"
      src="/images/cplogo.png"
      className="container d-flex align-items-center justify-content-center"
      width="100%"
    /> 
      
    </Col>
    <Row>
    <Col md={10}>    
    <h1>Highway Grade Crossing Test & Inspections </h1> 
    </Col>
    <Col md={2}>    
    <img
      alt="Card image cap"
      src="/images/cpsignal.png"
      className="container d-flex align-items-center justify-content-center"
      width="100%"
    /> 
    
    </Col>
    </Row>
    </Row>
      
    <Form onSubmit={handleSubmit}>
<Row>
<Col xl={4} lg={4} md={4}>
  <Row>
    <Col>
    <b>
    Crossing Name :</b>
    </Col> <Col>  {device.Name}   
    </Col>       
    </Row>
    
    
    <Row>
    <Col>
    <b>
    Location :</b>
    </Col> <Col>  {device.CrossingInfo.Location}   
    </Col>       
    </Row>


    <Row>
      
    <Col>
    <b>
    Inspection By :</b>
    </Col> 
    <Col md={6} lg={6} sm={12}>
              <InputGroup size="sm">
                
              <InputGroupAddon addonType="prepend">Name:</InputGroupAddon>
                <Input id="User" type="text" onChange={handleChange} value={inputs['User']&&inputs['User']} />
                
              </InputGroup>
              
              
              </Col>       
    </Row>  
    
       <Row >
       <Col>
                  <Label for="inspectionDate"><b> Inspection Date   : </b></Label>
                  </Col>
                  <Col>
                  <Input
                    type="date"
                    name="date"
                    id="inspectionDate"
                  
                   value={inputs["inspectionDate"]}
                    onChange={handleChange}
                  />
           
           </Col>
                </Row>
           
                <Row >
       <Col>
                  <Label for="InspectionRemarks"><b> Inspection Remarks   : </b></Label>
                  </Col>
                  <Col>
                  <Input
                    type="textarea"
                    name="InspectionRemarks"
                    id="InspectionRemarks"
                    placeholder="Remarks "
                    onChange={handleChange}
                    value={inputs["InspectionRemarks"]&&inputs["InspectionRemarks"]}
                  />
                </Col>
    
                
               
                </Row>
                </Col>
                
    </Row>
              
           
                
                <Row>
                <h5 className={classes.gapbottom}>Condition Master Key</h5>
                    </Row>
                    <div className={classes.keywithborder}>
                    C = Tests completed on all applicable components, no exceptions found, and condition left in compliance. <br/>
                    A = Advised S&C of maintenance need (identified in associated comments).<br/>
                    R = Repairs and/or Replacements made (identified in associated comments), test completed, and condition left in compliance.<br/>
                    NT = The equipment was not tested in this inspection.  <br/>
                    N = Test Not Applicable. <br/>
                    </div>
              
            
              {
                inspectiondata.map(function (x) {
                
                  return (
                    
                    <Row className={classes.rowWrapper}>
                 
                      
                      <Col md={12} lg={6}>
                        <Card className={classes.keymaster}>
                          
                            
                          
  
                          <CardBody className={classes.cardBody} >
                            {x.InspectionItems.map(function (y) {
                              return (                                                              
                                                 
                                CreateCheckControlV2(y.type,inputs[y.name]&&inputs[y.name],y.name,y.label,handleChange,isChecked,classes)
                                   
                              )
                            }
  
                            )}
  
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={6} md={12}  >
                      {x.InspectionItems.map(function (y) {
                              return (                                                              
                                      <></>           
                             //   CreateTextControl(y.type,y.value,y.name,y.label,handleChange,isChecked,classes)
                                   
                              )
                            }
  
                            )}
                      </Col>
                    </Row>
                  )
                })}
  
  
         
             
             
           

             
            
              {imageURL ? (
        <div ><img
          src={imageURL}
          alt="my signature"
          className={classes.signaturearea}
        /></div>
      ) : null}
             
             <Col md={{ span: 6, offset: 6 }} className={classes.submitbuttonsytle}>
                   
             <Button  type="button" color="primary"
                onClick={toggle}>Sign Inspection</Button>
                 
          <Button variant="primary" type="submit">
                  Save Inspection
                </Button>
                
          </Col> 

            </Form>

           
            <Modal isOpen={modal} toggle={toggle} contentClassName="custom-modal-style">
                <ModalHeader
                    toggle={toggle}>Draw Signature</ModalHeader>
                <ModalBody>
                <SignaturePad
              ref={sigCanvas}
              canvasProps={{
                className: "signatureCanvas"
              }}
            />
               <Button variant="primary"  type="button" className={classes.signinspectionbutton} onClick={save}> Save</Button>
              <Button variant="primary" type="button"  className={classes.signinspectionbutton} onClick={clear}> Clear </Button>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
           
        
 
         
            
          
       
             
    
      
      </Page>
    )
  }
  
  
  export default InspectionPageV2;