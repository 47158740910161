import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TablePagination from './TablePagination';
import {
  Table, Badge, 
  Label
} from 'reactstrap';


import _ from 'lodash';
import { getPageCount } from '../utils/utils';
import { Spinner } from 'reactstrap';

import { RiInformationLine } from 'react-icons/ri';




function tableHeadings(PowerIdicationStatus) {

  if(PowerIdicationStatus.length>0) {
  let headings = [...Object.keys(PowerIdicationStatus[0])];
  headings[0]='EventServerTime';
  headings.pop();
   if (PowerIdicationStatus) {
   }
     
  return _.uniq(headings);
  } else return [];
                
  //       headings.push(item);
        
  //     }
  //   );
  //}
  

}


function buildUrl(url, parameters) {
  let qs = '';
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = parameters[key];
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + '?' + qs;
  }

  return url;
}

const PowerIdicationTable = ({
  MACAddress,
  // currentPage,
  // pagesCount,
  // handlePageClick,
  // handlePreviousClick,
  // handleNextClick,
  //pageSize,
}) => {
  let [headings, setHeadings] = useState();
  let [loading, setLoading] = useState(true);
  const [PowerIdicationStatus, setPowerIdicationStatus] = useState([]);
  const [pageSize,setPageSize] = useState(20);
  const [pagesCount, setPagesCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [lightSensorData, setLightSensorData] = useState({});

  const toggle = () => {

    setModal(!modal);
    //console.log(LightData);

    //

  }
  // Modal open state
  const [modal, setModal] = React.useState(false);

  const getData = async () => {
    try {

      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.token) {

      const response = await fetch(
        buildUrl('/api/getpowerindications', {
          eipid: MACAddress,
        }),
        {
          method: 'GET', // or 'PUT'
          mode: 'cors',
          //body: JSON.stringify(data), // data can be `string` or {object}!
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token ,
          },
        },
      );

      const json = await response.json();
      console.log(json);
      
      setPowerIdicationStatus(json);
      setHeadings(tableHeadings(json));
      setPagesCount(getPageCount(json.length, pageSize));
      setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  function handlePageClick(e, index) {
    e.preventDefault();
    setCurrentPage(index);
  }
  function handleNextClick(e) {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
  }
  function handlePreviousClick(e) {
    e.preventDefault();
    setCurrentPage(currentPage - 1);
  }

  useEffect(() => {
    getData();
  }, []);
  let cnt = 0;
  return (
    <React.Fragment>
      {loading && (
        <Spinner
          style={{
            position: 'absolute',
            width: '50px',
            height: '50px',
            top: '100%',
            left: '50%',
          }}
        >
          Loading
        </Spinner>
      )}

 {}



      {/* <div>{MACAddress}</div>
      {PowerIdicationStatus.map((item, i) => {
        let val2 = Object.values(item.Data.Value);
        let val = val2.map((node, x) => {
          return (
            'MAC: ' +
            node.NodeMacAddress +
            ' Blink Count : ' +
            node.BlinkCount +
            ' '
          );
        });
        return <li key={i}> {val}</li>;
      })} */}
      <Table striped bordered responsive>
        <thead style={{ textAlign: 'center' }}>
          <tr>
            {headings &&
              headings.length > 0 &&
              headings.map((heading, index) => {
                if (index === 0) {
                  return  <th>{heading}</th>;
                } else {
                  return <th>{heading}</th>;
                }
              })}
          </tr>
        </thead>
        <tbody>
          {PowerIdicationStatus.length > 0 &&
            PowerIdicationStatus.slice(
              currentPage * pageSize,
              (currentPage + 1) * pageSize,
            ).map((Device, index) => {
             
              let refDevice =Device;
              
              return (                
                <tr key={Device._id}>
                  <td style={{ width: 210 }}>
                    {dateFromObjectId(Device._id).toLocaleString()}
                  </td>                
                
                  {headings &&
              headings.length > 0 &&
              headings.map((heading, index) => {
                if (index === 0) {
                  
                  return  '';
                  
                  
                } else 
                {
                  return        (
                  <td style={{ width: 210 }}>
                  {refDevice[''+headings[index]] }
                </td>        )          
                }
              })}              

                 
                             
                </tr>
              );
            })}
        </tbody>
      </Table>
      {pagesCount > 1 && (
        <TablePagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          handlePageClick={handlePageClick}
          handlePreviousClick={handlePreviousClick}
          handleNextClick={handleNextClick}
        />
      )}
    </React.Fragment>
  );
};

PowerIdicationTable.propTypes = {
  MACAddress: PropTypes.string.isRequired,
  // pagesCount: PropTypes.number.isRequired,
  // currentPage: PropTypes.number.isRequired,
  // pageSize: PropTypes.number.isRequired,
  // handlePageClick: PropTypes.func.isRequired,
  // handlePreviousClick: PropTypes.func.isRequired,
  // handleNextClick: PropTypes.func.isRequired,
};

export default PowerIdicationTable;

var dateFromObjectId = function (objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

