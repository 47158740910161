import { BehaviorSubject } from 'rxjs';
import useSWR from 'swr';

let global_devices_id={};

export const devicefetcher = url =>
  fetch(url).then(res =>
    res.json().then(data => {
      let Devices = [];
      if (Array.isArray(data)) {
        data.map((val, index) => {
          Devices[index] = val;
          global_devices_id[val.MACAddress] = val;
        });
      }
      
      return Promise.resolve(Devices);
    }),
  );
export const devicefetcher1 = url => fetch(url).then(res => res.json());

export function useDevices() {
  const { data, error, isLoading,mutate} = useSWR('/api/devices', devicefetcher);

  return {
    devices: data,
    isLoading:isLoading,
    isError: error,
    mutate:mutate,
  };
}
const subject = new BehaviorSubject();
export const messageService = {
  sendMessage: devices => subject.next(devices),
  clearMessages: () => subject.next(),
  getMessage: () => subject.asObservable(),
};
class DevicesServices {
  constructor() {
    var self = this;
    self.subject = new BehaviorSubject();
    self.Devices = null;
    self.ready = false;
    this.init();
  }

  init() {
    
    
    
    
    this.Devices =global_devices_id;
    
    // fetch('/api/devices')
    //   .then(response => response.json())
    //   .then(data => {
    //     if (Array.isArray(data)) {
    //       this.data = data.map((val, index) => {
    //         this.setDevice(val.MACAddress, val);
    //       });
    //     }
    //     let devices = this.getAllDevices();
    //     this.subject.next(devices);
        this.ready = true;
    
        //    this.callbacks.forEach(element => {
        //       element(this.getAllDevices());
        //    });
      // });
  }

  async refresh() {
    // init Deviecs Map
    this.Devices = {};
    // let data = await (await fetch('/api/devices')).json();
    // if (Array.isArray(data)) {
    //   this.data = data.map((val, index) => {
    //     this.setDevice(val.MACAddress, val);
    //   });
    // }
    // let devices = this.getAllDevices();
    // this.subject.next(devices);
    // this.ready = true;
  }

  getDevice(id) {
    if (this.Devices[id] !== undefined) {
      return this.Devices[id];
    } else {
      return undefined;
    }
  }

  getDevicebyMac(mac) {
    if (this.Devices[mac] !== undefined) {
      return this.Devices[mac];
    } else {
      return undefined;
    }
  }
  subscribe(callback) {
    var subscribemap = this.subject.asObservable().subscribe(callback);
    return subscribemap;
  }

  setDevice(id, device) {
    if (this.Devices[id] !== undefined) {
      this.Devices[id] = device;
      //Todo:copy data only
    } else {
      this.Devices[id] = device;
    }
  }

  getAllDevices() {
    if (this.devices === null) {
      this.refresh();
    }
    return Object.values(this.Devices);
  }
}

let DeviceServices = new DevicesServices();
export { global_devices_id};
export default DeviceServices;

