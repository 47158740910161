import React from 'react';
import data  from '../pages/data'
import {  NodeCard } from '../components/Card';
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardSubtitle,
  CardBody,
  CardText,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
  
} from 'reactstrap';



import Page from '../components/Page';
let commands= ["CaptureImage", "Reset", "ConfigurationUpload", "Ping","FirmwareUpload"];
const nodeData = [
  { id: 1, Node_ID:"13a200418fc124",EventDateTime:"2019-05-17-13:03:50", BlinkCount: '1',LightStatus:true, Color:"#E9575F",MaxLux:41,AvgLux:5,EventDuration:855},
  { id: 2,Node_ID:"13a200418fc124", EventDateTime:"2019-05-17-13:03:50",BlinkCount: '5',LightStatus:false, Color:"#E9564F",MaxLux:42,AvgLux:8,EventDuration:455},
  { id: 3, Node_ID:"13a200418fc124",EventDateTime:"2019-05-17-13:03:50",BlinkCount: '41',LightStatus:true, Color:"#E9563F",MaxLux:54,AvgLux:25,EventDuration:255},
  { id: 4, Node_ID:"13a200418fc124",EventDateTime:"2019-05-17-13:03:50",BlinkCount: '24',LightStatus:true, Color:"#FF7579",MaxLux:84,AvgLux:2,EventDuration:155},
  { id: 5, Node_ID:"13a200418fc124",EventDateTime:"2019-05-17-13:03:50",BlinkCount: '51',LightStatus:true, Color:"#E9673F",MaxLux:94,AvgLux:1,EventDuration:755},
];


var rgbToHex = function (rgb) { 
  var hex = Number(rgb).toString(16);
  if (hex.length < 2) {
       hex = "0" + hex;
  }
  return hex;
};

var fullColorHex = function(r,g,b) {   
  var red = rgbToHex(r);
  var green = rgbToHex(g);
  var blue = rgbToHex(b);
  return red+green+blue;
};
class DeveolperPage extends React.Component {
  
  state = {
    rSelected: null,
    cSelected: [],
    nodes:[],
    selectednode:0,
    macAddress:0,
    selectedCommand:commands[0],
    response:"",
    data:data,
    nodeData:nodeData,
    unfilterednodeData:[]
  };

   buildUrl(url, parameters) {
    let qs = "";
    for (const key in parameters) {
        if (parameters.hasOwnProperty(key)) {
            const value = parameters[key];
            qs +=
                encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
        }
    }
    if (qs.length > 0) {
        qs = qs.substring(0, qs.length - 1); //chop off last "&"
        url = url + "?" + qs;
    }

    return url;
}
  onCheckboxBtnClick(selected) {
    const index = this.state.cSelected.indexOf(selected);
    if (index < 0) {
      this.state.cSelected.push(selected);
    } else {
      this.state.cSelected.splice(index, 1);
    }
    this.setState({ cSelected: [...this.state.cSelected] });
  }

  submitForm(e) {
    e.preventDefault();
    console.log(+e.target[0].value);
    console.log(this.state);
 let data ={Command:this.state.selectedCommand,"Node_ID":this.state.cSelected};
    fetch(
        this.buildUrl("/api/commands", {
            mac_address: this.state.macAddress
            
        }),
        {
          method: 'PUT', // or 'PUT'
          mode:'cors',
          body: JSON.stringify(data), // data can be `string` or {object}!
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(response => console.log('Success:', JSON.stringify(response)))
        .catch(error => console.error('Error:', error));




  }

    handleNodeChange(event)
    {
    //this.state.nodes.push(event.target.value);
    //this.setState({nodes:this.state.nodes});
    //this.state.selectednode= +event.target.value;
    this.setState({selectednode:+event.target.value});
 }

 handleNodeAddClick(e)
 {
     this.state.nodes.push(this.state.selectednode);
    this.setState({nodes:this.state.nodes});

 }
 createCommandItems() {
   
    let items = [];         
    for (let i = 0; i <commands.length; i++) {             
         items.push(<option key={i} value={commands[i]}>{commands[i]}</option>);            
    }
    
    return items;
}  



componentDidMount() {
  console.log(this.props.location.state);
  fetch('/api/data/upload')
    .then(response => response.json())
    .then(data =>
      { this.setState({     unfilterednodeData:data.reverse() })
    console.log(this.state.unfilterednodeData);
    var result = data.map( (val,index)=>
    {
    
  

   if(val.Content!==undefined){

      var res={};
      res.id = index+1;
      res.Node_ID= val.Content.Node_ID;
      res.EventDateTime = val.Content.EventStartTime.Value;
      res.BlinkCount = +val.Content.BlinkCount.Value;
      res.LightStatus= val.Content.BlinkCount.Value
      res.Color = '#'+ fullColorHex(val.Content.AvgRed.Value,val.Content.AvgGreen.Value,val.Content.AvgBlue.Value);
      res.MaxLux = val.Content.MaxLux.Value;
      res.AvgLux = val.Content.AvgLux.Value;
      res.EventDuration = (val.Content.TimeEnd.Value-val.Content.TimeStart.Value);
      
      return res;

   }
    });

    console.log(result);
    

  this.setState({nodeData:result});
   
    


    });
}


  render() {
    return (
      <Page
        className="ButtonPage"
        title="DataView"
        breadcrumbs={[{ name: 'Data View', active: true }]}
      >

      <Row>        
        

      <NodeCard   GateWayId="" nodedata={this.state.nodeData} />
        
        
        </Row>
        {
        <Row>
          <Col md="6" sm="12" xs="12">
            <Card className="mb-3">
              <CardHeader>Device Control</CardHeader>
              <CardBody>
              <CardSubtitle>Node Selection</CardSubtitle>
              <ButtonGroup>
                <Button
                  color="primary"
                  onClick={() => this.onCheckboxBtnClick(1)}
                  active={this.state.cSelected.includes(1)}
                >
                  One
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.onCheckboxBtnClick(2)}
                  active={this.state.cSelected.includes(2)}
                >
                  Two
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.onCheckboxBtnClick(3)}
                  active={this.state.cSelected.includes(3)}
                >
                  Three
                </Button>
              </ButtonGroup>
              <CardText>
                Selected: {JSON.stringify(this.state.cSelected)}
              </CardText>

              <Form onSubmit={(e)=>{this.submitForm(e)}}>
              <FormGroup>
              <Label for="GatewayNode">GateWay Mac</Label>
              <Input  type="text"               
                 name="GatewayNode"  onChange={(e)=>{this.setState({ macAddress: e.target.value })}}
              />
            </FormGroup>
            <Label for="select">Commands</Label>
              <Input type="select" name="select"  onChange={(e)=>{this.setState({ selectedCommand: e.target.value })}}>
              {this.createCommandItems()}
            </Input>
              <Button color="primary">            
              Submit
                </Button>
          
              </Form>
           
           

                
            </CardBody>
            </Card>
          </Col>

       
        </Row>
        
      }
     
      </Page>
    );
  }
}

export default DeveolperPage;
