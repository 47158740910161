import React from 'react';
import PropTypes from 'prop-types';
import CrossingLightSVG from './CrossingLight';
import { useState, useEffect, useRef } from 'react';
import { timer } from 'rxjs';

function CrossingLightBlinker({ MaxBlinkCount }) {
  const [count, setCount] = useState(0);
  const [LightOn, setLightOn] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      if (count <= MaxBlinkCount * 2 - 2) {
        setCount(count => count + 1);
        console.log(count);
        if (count % 2 === 0) {
          setLightOn(true);
        } else {
          setLightOn(false);
        }
      } else {
        setLightOn(false);
        setCount(count => 0);
      }
    }, 500);
    return () => {
      clearTimeout(id);
      console.timeStamp('end');
    };
  }, [LightOn, MaxBlinkCount]);

  return <CrossingLightSVG isON={LightOn}></CrossingLightSVG>;
}

CrossingLightBlinker.propTypes = {
  MaxBlinkCount: PropTypes.number.isRequired,
};

export default CrossingLightBlinker;
