import React from 'react';
import data from './data';
import { NodeCard } from '../components/Card';
import { GatewayListCard } from '../components/Card';
import { ImageCard } from '../components/Card';
import DeviceServices from '../service/DeviceService';
import { socketConnect } from '../SocketIO';


import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardSubtitle,
  CardBody,
  CardText,
  Form,
  Input,
  Label,
} from 'reactstrap';

import Page from '../components/Page';

class DevicesPage extends React.Component {
  state = {
    eIPList:[],
    rSelected: null,
    cSelected: [],
    nodes: [],
    selectednode: 0,
    macAddress: 0,
    response: '',
    data: data,
    unfilterednodeData: [],
    selectedDevice: undefined,
    socket: undefined,
    newImage: undefined,
  };

  buildUrl(url, parameters) {
    let qs = '';
    for (const key in parameters) {
      if (parameters.hasOwnProperty(key)) {
        const value = parameters[key];
        qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
      }
    }
    if (qs.length > 0) {
      qs = qs.substring(0, qs.length - 1); //chop off last "&"
      url = url + '?' + qs;
    }

    return url;
  }
  ConfigurtionViewClick = (index, typ) => {
    let val = DeviceServices.getAllDevices();
    let device = val[Number(index)];
    if (typ === 'slm') {
      this.props.history.push({
        pathname: '/devices/slm',
        state: {
          from: this.props.location,
          data: device,
        },
      });
    } else if(typ==='glm') {
      this.props.history.push({
        pathname: '/devices/glm',
        state: {
          from: this.props.location,
          MacAddress: device.MACAddress,
        },
      });
    }
     else if(typ==='summury') {
        this.props.history.push({
          pathname: '/devices/summury',
          state: {
            from: this.props.location,
            MacAddress: device.MACAddress,
          },
        });
    }else if(typ==='inspection') {
      this.props.history.push({
        pathname: '/devices/inspection',
        state: {
          from: this.props.location,
          MacAddress: device.MACAddress,
        },
      });
  }


    this.setState({ selectedDevice: device });
  };

  SendMessage() {
    this.props.socket.emit('message', 'Hello world!');
  }

  async GetDevices()
  {
      await DeviceServices.refresh();
      let result =DeviceServices.getAllDevices();
      this.setState({ eIPList:result});

  }

  componentDidMount() {

    if(DeviceServices.ready){
    let result =DeviceServices.getAllDevices();
    this.setState({ eIPList:result});
    }else
    {
    this.GetDevices();

    }
    
    if (this.props.location.state) {
      var device = DeviceServices.getDevice(this.props.location.state.id);
      this.setState({ selectedDevice: device });

      this.props.history.push({
        pathname: '/devices/slm',
        state: {
          from: this.props.location,
          data: device,
        },
      });

    }

    this.props.location.state = null;

    var self = this;
    this.props.socket.on('detectionUpdateMessage', function (data) {
      let val = DeviceServices.getAllDevices();

      let result = val.map((eip, index) => {
        if (eip.MACAddress == data.GatewayMacAddress) {
          var nodes = Object.values(data.Data.Value);
          eip.Nodes = nodes;
          var copiedObj = JSON.parse(JSON.stringify(eip));

          self.setState({ selectedDevice: copiedObj });
        }
        return eip;
      });

      console.log(result);
    });

    this.props.socket.on('crossingupdate', async function (data) {
      //await DeviceServices.refresh();
      //let val = DeviceServices.getAllDevices();
     // self.setState({ eIPList: val });
    
    });

    console.log('got device ' + device);
    fetch('/api/data/upload')
      .then(response => response.json())
      .then(data => {
        this.setState({ unfilterednodeData: data.reverse() });
        console.log(this.state.unfilterednodeData);
      });

    this.props.socket.on('newimage', function (data) {
      self.setState({ newImage: JSON.parse(data) });
      console.log('new image received');
    });
  }

  handleCaptureImage(index) {
    let data = { Command: 'CaptureImage', Node_ID: [index + 1] };
    fetch(
      this.buildUrl('/api/commands', {
        mac_address: this.state.selectedDevice.MACAddress,
      }),
      {
        method: 'PUT', // or 'PUT'
        mode: 'cors',
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then(res => res.json())
      .then(response => console.log('Success:', JSON.stringify(response)))
      .catch(error => console.error('Error:', error));
  }

  render() {
    return (
      <Page
        className="ButtonPage"
        title="Crossings"
        breadcrumbs={[{ name: 'Crossings', active: true }]}
      >
        {this.state.selectedDevice && (
          <NodeCard
            selectDevice={this.state.selectedDevice}
            Nodes={this.state.selectedDevice.Nodes}
            GateWayId={this.state.selectedDevice.ID}
            NodeId={this.state.selectedDevice.MACAddress}
            nodedata={this.state.nodeData}
            MACAddress={this.state.selectedDevice.MACAddress}
            Name={this.state.selectedDevice.Name}
            handleCaptureImage={this.handleCaptureImage.bind(this)}
          />
        )}

        {this.state.selectedDevice === undefined && (
          <GatewayListCard
            eIPList={this.state.eIPList}
            onConfigurationChange={this.ConfigurtionViewClick}
          ></GatewayListCard>
        )}

        {
          <Row>
            <Col md="6" sm="12" xs="12">
              {this.state.newImage && (
                <ImageCard newImage={this.state.newImage}></ImageCard>
              )}
            </Col>
          </Row>
        }
      </Page>
    );
  }
}

export default socketConnect(DevicesPage);
