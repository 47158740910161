import React from 'react';
import { Route,Redirect } from 'react-router-dom';

const PrivateLayoutRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
      {localStorage.getItem('user') ?
       (<Component {...props} /> )
      : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)}
      </Layout>
    )}
  />
);

export default PrivateLayoutRoute;
