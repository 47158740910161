

import Page from '../components/Page';
import Typography from '../components/Typography';
import React from 'react';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledAlert,
} from 'reactstrap';

const SummuryPage = () => {
  return (
    <Page title="Summury" breadcrumbs={[{ name: 'summury', active: true }]}>
      
   
    </Page>
  );
};

export default SummuryPage;
