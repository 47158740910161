export function buildUrl(url, parameters) {
  let qs = '';
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = parameters[key];
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + '?' + qs;
  }

  return url;
}

export function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
 }   
 

export function getPageCount(dataLength, pageSize) {
  let count = parseInt(dataLength / pageSize);
  let reminder = dataLength % pageSize;
  if (reminder === 0) {
    return count;
  }
  return count + 1;
}
