import Page from '../components/Page';
import React from 'react';
import GLMVideosPage from './GLMVideosPage';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import GLMDevice from './GLMDevice';
import classnames from 'classnames';

export default class GLMPage extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      MacAddress: null,
      activeTab: '1',
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      console.log(this.props.location.state.MacAddress);
      this.setState({
        MacAddress: this.props.location.state.MacAddress,
        activeTab: '1',
      });
    }
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        MacAddress: this.props.location.state.MacAddress,
      });
    }
  }
  render() {
    return (
      <Page
        title="GLM Devices"
        breadcrumbs={[
          {
            name: 'Devices',
            active: false,
          },
          {
            name: 'GLM',
            active: true,
          },
        ]}
      >
        <div> {console.log(this.props.location.state.MacAddress)}</div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => {
                this.toggle('1');
              }}
            >
              Live
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => {
                this.toggle('2');
              }}
            >
              Videos
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            {this.props.location.state.MacAddress && (
              <GLMDevice
                MacAddress={this.props.location.state.MacAddress}
              ></GLMDevice>
            )}
          </TabPane>
          <TabPane tabId="2">
            <GLMVideosPage
              MacAddress={this.props.location.state.MacAddress}
            ></GLMVideosPage>
          </TabPane>
        </TabContent>
      </Page>
    );
  }
}
