import React from 'react';
import PropTypes from '../../utils/propTypes';

import {Row,Col,Label,Button, Card, CardImg,CardHeader, CardImgOverlay, CardTitle, CardText ,CardBody} from 'reactstrap';

const ImageCard = ({newImage, ...restProps }) => {
  console.log(newImage);
  const {_id, deviceMac, imageData, NodeMacAddress,NodeName, rdateTime} = newImage;

 return (
    <Card  className="text-top">
 <div className="imagecardstyle">
 {rdateTime && (
   <div>
     <b>Date  : 
     </b>{new Date(rdateTime).toLocaleString()}
   </div>
 )}
 {NodeName &&
 <div>
   <b> Name:
   </b> {NodeName}
 </div>
}
 {NodeMacAddress && <div>
   <b>
     Node  :</b>
   {NodeMacAddress}
 </div>}
 </div> 
   <CardImg
     width="100%"
     src={imageData.path}
     className="rotateimg180"
     alt="Card image cap"/>
   <CardImgOverlay>

     <CardTitle>
     
     </CardTitle>

   </CardImgOverlay>
 </Card>
 )
}
export default ImageCard