import React from 'react';
import {
  Button, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';

function FlashRateInformation(modal, toggle, lightSensorData) {
  return   <Modal isOpen={modal} toggle={toggle} contentClassName="custom-modal-style">
    <ModalHeader
      toggle={toggle}>Information</ModalHeader>
    <ModalBody>
    <Card className="mb-3">
              
              <CardBody>
                <Row>
                  <Col>
                    <Card body>
                      <Table >
                        <tbody>
                          <tr>
                            <th scope="row"> Flash rate (per minute) </th>
                            <td> {lightSensorData.FlashRate}</td>                        
                          </tr>
                          <tr>
                            <th scope="row"> Average RGB (Color)</th>
                            <td> ({lightSensorData.AvgRed}, {lightSensorData.AvgGreen}, {lightSensorData.AvgBlue} )</td>                            
                          </tr>
                          <tr>
                            <th scope="row">Crossing duration (minutes)</th>
                            <td> {lightSensorData.crossingDuration}</td>
                            
                          </tr>
                          <tr>
                            <th scope="row">Average light intensity (Lux)</th>
                            <td>{lightSensorData.AvgLux}</td>
                            
                          </tr>
                          <tr>
                            <th scope="row">Minimum light intensity (Lux)</th>
                            <td>{lightSensorData.MinLux}
</td>
                            
                          </tr>
                          <tr>
                            <th scope="row">Maximum light intensity (Lux)</th>
                            <td> {lightSensorData.MaxLux}</td>
                            
                          </tr>
                        </tbody>
                      </Table>
                    </Card>
                  </Col>                 
                </Row>
              </CardBody>
            </Card>
      {/* <div> Flash rate (per minute) : {lightSensorData.FlashRate}

      </div>
      <div className=''> Average RGB (Color): ({lightSensorData.AvgRed}, {lightSensorData.AvgGreen}, {lightSensorData.AvgBlue} )

        <div className='' style={{ display: 'inline-flex', margin: 'auto', flexWrap: "wrap", justify: "center", align: "center", flex: "1" }}>
          <div style={{ backgroundColor: '#' + lightSensorData.AvgRedColor, width: '1em', height: '1em' }}></div>
        </div>
        {/* <div className='mx-2 my-2' style={ {display: 'inline-flex',margin:'auto', flexWrap:"wrap",justify:"center",align:"center",flex:"1"}}>
  <div style={{backgroundColor:'#'+lightSensorData.AvgRedColor,   width:'2em', height:'2em'}}></div><div style={{backgroundColor:'#'+lightSensorData.MinRedColor,width:'2em', height:'2em'}}></div><div style={{backgroundColor:'#'+lightSensorData.MaxRedColor,width:'2em', height:'2em'}}></div>
  </div> }
      </div>
      <div className=''> Crossing duration (minutes) : {lightSensorData.crossingDuration}
      </div>

      <div className=''> Average light intensity (Lux) : {lightSensorData.AvgLux}

      </div>
      <div className=''> Minimum light intensity (Lux) : {lightSensorData.MinLux}

      </div>
      <div className=''> Maximum light intensity (Lux) : {lightSensorData.MaxLux}

      </div> */}
      
    </ModalBody>

    <ModalFooter>
      <Button color="primary" size="sm" onClick={toggle}>Close</Button>
    </ModalFooter>
  </Modal>;
}

export default FlashRateInformation;

