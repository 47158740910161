import React from 'react';
import PropTypes from '../utils/propTypes';
import * as moment from 'moment'

import {
  ListGroup,
  ListGroupItem,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';

export const propTypes = {
  eIPList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      Name: PropTypes.string,
      NodeCount: PropTypes.number,
      MACAddress: PropTypes.string,
      UpdatedAt:PropTypes.number,
      Nodes: PropTypes.arrayOf(PropTypes.shape({ position:PropTypes.number,MACAddress:PropTypes.string})),
    })
  ),
};

const EIPList = ({ eIPList, ...restProps }) => {
  return (
    <ListGroup flush {...restProps}>
      {eIPList.map(({ id, Name, NodeCount,MACAddress,UpdatedAt,Nodes} = {}) => (
        <ListGroupItem key={id} className="border-0">
          <FormGroup headers>
            <Label>              
              {Name}
            </Label>
            <Label>              
            {NodeCount}
          </Label>
          <Label>              
          {MACAddress}
        </Label>
        <Label>{moment(UpdatedAt).fromNow()} at {moment(UpdatedAt).format('LLL') }</Label>
        <Button>Config</Button>
          </FormGroup>
        </ListGroupItem>
      ))}
      
    </ListGroup>
  );
};

EIPList.propTypes = propTypes;

EIPList.defaultProps = {
    eIPList: [],
};

export default EIPList;
