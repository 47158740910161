import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Map, Marker, TileLayer,Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet'
import PropTypes from '../utils/propTypes';



export const pointerIcon = new L.Icon({
  iconUrl: require('../assets/img/CrossingIcon5.png'),
  iconRetinaUrl: require('../assets/img/CrossingIcon5.png'),
  iconAnchor: [115, 95],
  popupAnchor: [10, -44],
  iconSize: [152, 152]   
  
})

let data = {
    device: [    
      { "name": " Device 1", "coordinates": [-93.208008, 44.957024], "BlinkCount": 15 },
      { "name": " Device 2", "coordinates": [ -74.0059731,40.7143528], "BlinkCount": 25 },
      { "name": "CN Rail", "coordinates":   [-114.15344238, 51.06901666], "BlinkCount": 20 },
  
  ],
  minLat: 39.1751,
  maxLat: 55.7558,
  minLong: -114.6173,
  maxLong: -60.6917
}

 class GISMap extends Component {
   
   constructor()
   {
      super();
     this.state={redirect:false , id:""};

   }


 
  groupClick(event) {
    console.log("Clicked on marker " + event.target.options.selected_id);
    this.setState({redirect:true,id:event.target.options.selected_id})
  }
  
  render() {
    if (this.state.redirect) {
      return (<Redirect  to={{
        pathname: "/devices",
        state: {
         from: this.props.location,
         id: this.state.id

        }
     }}/>);
    }
      var centerLat = (data.minLat + data.maxLat) / 2;
      var distanceLat = data.maxLat - data.minLat;
      var bufferLat = distanceLat * 0.05;
      var centerLong = (data.minLong + data.maxLong) / 2;
      var distanceLong = data.maxLong - data.minLong;
      var bufferLong = distanceLong * 0.15;

     
      return (
        <div>
        <Map
        style={{ height: "680px", width: "100%" }}
        zoom={5}
        center={[centerLat, centerLong]}
        bounds={[
          [data.minLat - bufferLat, data.minLong - bufferLong],
          [data.maxLat + bufferLat, data.maxLong + bufferLong]
        ]}>
        <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
    accessToken="pk.eyJ1IjoidXNtYW5xdXJlc2hpIiwiYSI6ImNqdzlmNG0yazBpcHA0OHBkYmgyZHdyZjAifQ.2O9HKhWB6EG-OZk3g4zdOg"
    id ="mapbox/streets-v11"
      />
        
        {this.props.devices && this.props.devices.map((device,k) => { 
        
          return (
            <Marker
            key={k}
            position={[device.GPSLocation.Latitude, device.GPSLocation.Longitude]}
            icon={pointerIcon}
            onClick={(event)=>{this.groupClick(event)}}
            selected_id={device.MACAddress}
          >
            <Tooltip direction="right" offset={[-8, -2]} opacity={1}>
              <span>{device["Name"] + ": NodeCount:" + device["NodeCount"]}</span>
            </Tooltip>
          </Marker>)
        })
        }
      </Map>
        </div>
      );
    }
  }
  export const propTypes = {
    devices: PropTypes.arrayOf(PropTypes.object)
  };
  
  
  GISMap.propTypes = propTypes;
  
  GISMap.defaultProps = {
    devices: [],
  };
  export default GISMap;