import Page from '../components/Page';
import React from 'react';
import { socketConnect } from '../SocketIO';
import GLMDeviceControl from '../components/GLMDevice';
import GLMVideosPage from './GLMVideosPage';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { buildUrl } from '../utils/utils';
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardImgOverlay,
  CardLink,
  CardText,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import GLMLiveModal from '../components/GLMLiveModel';

class GLMDevice extends React.Component {
  constructor(props) {
    super(props);
    this.getGLMDevices = this.getGLMDevices.bind(this);
    this.state = {
      MACAddress: null,
      glmdevices: [
        // {
        //   DeviceName: 'GLM # 1',
        //   DeviceType: 'GLM',
        //   DeviceId: 'GLM1',
        //   InstallationDate: '',
        //   VPN_IP: '10.0.0.1',
        //   WIFI_IP: '192.168.1.32',
        //   UpdatedAt: '2019-04-23T18:25:43.511Z',
        // },
        // {
        //   DeviceName: 'GLM # 2',
        //   DeviceType: 'GLM',
        //   DeviceId: 'GLM2',
        //   InstallationDate: '',
        //   VPN_IP: '10.0.0.8',
        //   WIFI_IP: '192.168.1.43',
        //   UpdatedAt: '2019-04-23T18:25:43.511Z',
        // },
      ],
    };
  }

  async getGLMDevices(MACAddress) {
    var self = this;
    try {
      const response = await fetch(
        buildUrl('/api/getGLMDevices', {
          mac_address: MACAddress,
        }),
        {
          method: 'GET', // or 'PUT'
          mode: 'cors',
          //body: JSON.stringify(data), // data can be `string` or {object}!
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const json = await response.json();
      //console.log(json);
      self.setState({ glmdevices: json });
    } catch (error) {
      console.log('error', error);
    }
  }

  componentDidMount(props) {
    // fetch('/api/getGLMDevices')
    //   .then(response => response.json())
    //   .then(data => {
    //     this.setState({ glmdevices: data });
    //     console.log(data);
    //   });
    this.getGLMDevices(this.props.MacAddress);
    this.setState({ MACAddress: this.props.MacAddress });

    var self = this;
    this.props.socket.on('glmupdate', function (data) {
      self.getGLMDevices(self.state.MACAddress);
    });
  }

  render() {
    return (
      <Row>
        <GLMDeviceControl glmdevices={this.state.glmdevices}>
          {' '}
        </GLMDeviceControl>
      </Row>
    );
  }
}

export default socketConnect(GLMDevice);
