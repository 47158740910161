import Page from '../components/Page';
import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import TablePagination from '../components/TablePagination';

import _, { head } from 'lodash';
import { getPageCount } from '../utils/utils';
import { Spinner } from 'reactstrap';


var dateFromObjectId = function (objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

function tableHeadings(inspections) {
  let headings=[];
  if (inspections) {
    headings = Object.keys(inspections[0]);
  }
      

  return _.uniq(headings);
}

function buildUrl(url, parameters) {
  let qs = '';
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = parameters[key];
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + '?' + qs;
  }

  return url;
}

const InspectionListPage = () => {

  let [headings, setHeadings] = useState([]);
  let [loading, setLoading] = useState(true);
  const [Inspections, setInspections] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pagesCount, setPagesCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);


  const getData = async () => {
    try {
      const response = await fetch(
        buildUrl('/api/inspections', {
          
        }),
        {
          method: 'GET', // or 'PUT'
          mode: 'cors',
          //body: JSON.stringify(data), // data can be `string` or {object}!
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const json = await response.json();
      json.reverse();
      console.log(json);
      setInspections(json);
      setHeadings(tableHeadings(json));
      setPagesCount(getPageCount(json.length, pageSize));
      setLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  function handlePageClick(e, index) {
    e.preventDefault();
    setCurrentPage(index);
  }
  function handleNextClick(e) {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
  }
  function handlePreviousClick(e) {
    e.preventDefault();
    setCurrentPage(currentPage - 1);
  }

  useEffect(() => {
    getData();
  }, []);
  let cnt = 0;


  return (
    <Page
      title="Reports"
      breadcrumbs={[{ name: 'Inspections Reports', active: true }]}
      className=""
    >
  
      <Row>
      <React.Fragment>
      {loading && (
        <Spinner
          style={{
            position: 'absolute',
            width: '50px',
            height: '50px',
            top: '100%',
            left: '50%',
          }}
        >
          Loading
        </Spinner>
      )} 
      
      <Table striped bordered responsive>
      <thead style={{ textAlign: 'center' }}>
        <tr>
          {headings &&
            headings.length > 0 &&
            headings.map((heading, index) => {
              if(heading=='id') return;
              if(heading=='__v') return;
              if(heading=='CrossingInfo') return <><th>Asset ID</th><th>Location</th></>;
              if(heading=='EIP_MAC') return;
              if(heading=='InspectionType') return;
              if(heading=='Inspection_Template') return;
              if(heading=='Inspection_Results') return;

              if (index === 0) {
                return <th>Submission Time</th>;
              } else {
                return <th>{`${heading.replaceAll('_',' ')}`}</th>;
              }
            })}
        </tr>
      </thead>
      <tbody>
        {Inspections.length > 0 &&
          Inspections.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          ).map((Inspection, index) => {
           
            return (
              <tr key={Inspection._id}>
                <td style={{ width: 210 }}>
                  {dateFromObjectId(Inspection._id).toLocaleString()}
                </td>
                {headings &&
                  headings.length > 0 &&
                  headings.map((heading, index) => {
                    if (index > 0) {
                      if(heading=='id') return;
                      if(heading=='__v') return;                      
                      if(heading=='CrossingInfo') {return <><td style={{ textAlign: 'center', width: 210}}><div></div>{Inspection[heading].AssetID} </td><td style={{ textAlign: 'center', width: 210}}><div></div>{Inspection[heading].Location} </td></>} 
                      if(heading=='EIP_MAC') return;
                      if(heading=='Test_Date') return <><td style={{ textAlign: 'center', width: 210}}>{new Date(Inspection[heading]).toISOString().split("T").at(0)} </td></>;
                     
                      if(heading=='Inspection_Template') return;
                      if(heading=='Inspection_Results') return;
                      if(heading=='InspectionType') return;
                      
                      if(heading=='Inspection_signature') {return <td style={{ textAlign: 'center', width: 210}}><img src={Inspection[heading]} style={{ maxWidth: '50%', objectFit: 'scale-down' }}/> </td>} 
                      if(heading=='Inspection_Report') {return <td style={{ textAlign: 'center' }}><a target="_blank" href={window.location.protocol + "//" +window.location.host+"/uploads/reports/"+Inspection[heading]}>View Report</a> </td>} 
                      
                      return (
                        <td style={{ textAlign: 'center' }}>
                          {Inspection[heading]}
                        </td>
                      );
                    }
                  })}
              </tr>
            );
          })}
      </tbody>
    </Table>
    {pagesCount > 1 && (
      <TablePagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        handlePageClick={handlePageClick}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
      />
    )}
  </React.Fragment>
      </Row>

     
    </Page>
  );
};

export default InspectionListPage;
