import React from 'react';
import PropTypes from '../../utils/propTypes';
import * as moment from 'moment';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Button,
  Card,
  CardImg,
  CardHeader,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardBody,
  Container,
} from 'reactstrap';
import { FaPencilAlt,FaSave,FaEraser} from 'react-icons/fa';



import EIPList, {
  propTypes as EIPListpropTypes,
} from '../../components/EdgeImageProcesserList';

import backgroundImage from '../../assets/img/slmgatewayIcon.png';
import { withRouter } from 'react-router';



function buildUrl(url, parameters) {
  let qs = '';
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = parameters[key];
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + '?' + qs;
  }

  return url;
}

let defaultCrossingInfo = {
  Name : "TestCrossing",
  AssetID : "1234",
  Division : "MEDICINE HAT 2",
  SubDivision : "BROOKS 5",
  MilePost : "BROO 152.91 - 152.91",
  Location : "Range Road 282 - West of Dalemead",
  Province : "BC"
}
let EmptyCrossingInfo = {
  Name : "",
  AssetID : "",
  Division : "",
  SubDivision : "",
  MilePost : "",
  Location : "",
  Province : ""
}

const GatewayListCard = ({
  image,
  title,
  subtitle,
  eIPList,
  onConfigurationChange,
  ...restProps
}) => {
  let finalArr = [],
    columns = [];
    const toggle = (id,Name,CrossingInfo) => 
    {
      setselectedMAC(id);
        setCrossingName(Name);  
        setCrossingInfo(CrossingInfo);  
        setModal(!modal);
          console.log(id);
        
         //

    }
  // Modal open state
  const [modal, setModal] = React.useState(false);
  const [selectedMAC, setselectedMAC] = React.useState("");
  const [crossingName, setCrossingName] = React.useState("");
  const [crossingInfo, setCrossingInfo] = React.useState({});


  const updateCrossingName=async (ID,CrossingName,CrossingInfo)=>{
    try {
  
  const requestOptions = {
    method: 'PUT',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ID: ID,Name:CrossingName,CrossingInfo: CrossingInfo})
  };
  
  const response = await fetch(
    buildUrl('/api/crossing', {
      
    }), requestOptions);
  
    console.log(response.json());
  } catch (error) {
    console.log('error', error);
  }

  
  }

  const clear = () => 
  {
    setCrossingName("");
    setCrossingInfo(EmptyCrossingInfo);
  }
    //

    const onCrossingNameChange=(event)=>
    {
      setCrossingName(event.target.value);
    }

    const handleCrossingInfoChanged=Key => e =>
    {
      //e.target.preventDefault();
      crossingInfo[Key]=e.target.value;
      let shallow = Object.assign({}, crossingInfo);
      setCrossingInfo(shallow);
    }
  
  const save = () =>
  {
    console.log(crossingName);
    updateCrossingName(selectedMAC,crossingName,crossingInfo);
  }
  let result=null;
  if(!isEmpty(crossingInfo))
  {

     result= getCrossingInfoDiv(crossingInfo, handleCrossingInfoChanged);  
  }else{

   result= getCrossingInfoDiv(defaultCrossingInfo, handleCrossingInfoChanged);
   setCrossingInfo(defaultCrossingInfo);
  }
  let eipListMap = eIPList.map(
    ({ ID, Name, NodeCount, MACAddress, UpdatedAt, Nodes ,CrossingInfo} = {}, index) => {
      //  console.log(this);

      return (
        // columns.push(

        <Col
          md={{ size: 6, offset: 0 }}
          sm={12}
          xs={12}
          className="mb-3"
          key={index}
        >
          <Card className="flex-row">
            <CardImg
              className={index % 2 === 0 ? 'card-img-right' : 'card-img-left'}
              src={image}
              style={{ width: 'auto', height: 150, margin: 'auto' }}
            />
            <CardBody>

              


              <CardTitle >             
              
                <b>Crossing Name:</b> {Name}                  
             
              
                <Button className="float-right" outline  size="sm" onClick={()=>{toggle(MACAddress,Name,CrossingInfo);}} ><FaPencilAlt/></Button>
              
              </CardTitle>
              {CrossingInfo &&
              <div>
                  <Label>
                    <b style={{marginRight:"5px"}}>Asset ID:  </b>
                     {CrossingInfo.AssetID}
                  </Label>
                </div>}
              <div>
                <Label>
                  <b>SLM Node Count: </b>
                  {NodeCount}
                </Label>
              </div>
              <div>
                <Label>
                  <b>EIP ID: </b>
                  {MACAddress}
                </Label>
              </div>
            
                <div>
                    {UpdatedAt && (
                      <Label>
                        <b>Updated At: </b>{' '}
                        {moment(UpdatedAt).fromNow() +
                          ' at ' +
                          moment(UpdatedAt).format('LLL')}{' '}
                      </Label>
                    )}
                  </div><Button
                    mindex={index}
                    size="sm"
                    onClick={event => {
                      console.log();
                      var index = event.target.attributes.mindex.value;
                      onConfigurationChange(index, 'slm');
                    } }
                  >
                    Inspection
                  </Button>
            </CardBody>
          </Card>
        </Col>

        //)
      );
      // after three items add a new row
      // if((index) % 2 === 0) {
      //   finalArr.push(<Row>{columns}</Row>);
      //   columns = [];
      // }
    },
  );

  return (  
    <>
     
    <Row>{eipListMap}</Row>
    <Modal isOpen={modal} toggle={toggle} contentClassName="custom-modal-style">
    <ModalHeader
        toggle={toggle}>Modify</ModalHeader>
    <ModalBody>
      <div  className='mx-8 my-4'> <b  style={{marginLeft:"35px"}}>Crossing Name: </b><span style={{margin:"10px"}}> </span>
      <input type="text" placeholder={crossingName} onChange={onCrossingNameChange} value={crossingName} className='mx-12 my-10'/>
      </div>
       <Container>
          <Row className='mx-2 my-4' key={crossingInfo}>{ result}
          </Row></Container>
      
     
      
      {/* <div  className='mx-2 my-2'>Asset ID: 
      <input type="text" placeholder={crossingInfo.AssetID} onChange={(e)=>{handleCrossingInfoChanged(e,crossingInfo.AssetID); }} value={crossingInfo.AssetID} className='mx-2 my-2'/>
      </div>
      <div  className='mx-2 my-2'> MilePost: 
      <input type="text" placeholder={crossingInfo.MilePost} onChange={(e)=>{handleCrossingInfoChanged(e,crossingInfo.MilePost); }} value={crossingInfo.MilePost} className='mx-2 my-2'/>
      </div>
      <div  className='mx-2 my-2'>Division: 
      <input type="text" placeholder={crossingInfo.Division} onChange={(e)=>{handleCrossingInfoChanged(e,crossingInfo.Division); }} value={crossingInfo.Division} className='mx-2 my-2'/>
      </div>
      <div >  */
      }
      <div >
    <Button variant="primary"  type="button"  size="sm"  onClick={save} outline className='mx-2 my-4'> <span> Save</span> <FaSave/> </Button>
              <Button variant="primary"  size="sm"  type="button"  onClick={clear} outline className='mx-2 my-4' ><span> Clear</span> <FaEraser/> </Button>
              </div>
                </ModalBody>
                
                <ModalFooter>
                    <Button color="primary" size="sm"  onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
            
  </>
  );
};

GatewayListCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  eIPList: EIPListpropTypes.eIPList,
  onConfigurationChange: PropTypes.func.isRequired,
};

const GatewayList = withRouter(GatewayListCard);

GatewayListCard.defaultProps = {
  image: backgroundImage,
  title: 'Gateway ',
  subtitle: 'working on...',
  onConfigurationChange: () => {},
};

const isEmpty = (obj) => {
  for(let key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}
export default GatewayList;
function getCrossingInfoDiv(crossingInfo, handleCrossingInfoChanged) {
  
  return Object.keys(crossingInfo).map(key => {

    return (<div key={key} className='mx-6 my-2'>
      <Col>
    <b>   {key.replace(/([a-z])([A-Z])/g, '$1 $2').trim()} </b></Col> 
       
       <Col> <input type="text" placeholder={key} onChange={handleCrossingInfoChanged(key)} value={crossingInfo[key]} className='mx-8 my-2' /></Col></div>);


  });
}

