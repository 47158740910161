import {useCallback} from 'react'
import {
    ReactCompareSlider,  
    ReactCompareSliderImage,
    styleFitContainer
  } from "react-compare-slider";

const  CompareImage = ({ style, ...props }) => {
    const handlePositionChange = useCallback(
      (position) => //console.log("[CustomHandle]", position),
      []
    );
  
    return (
      <ReactCompareSlider
        {...props}
      style={{...styleFitContainer(), ...style}}
      
        onPositionChange={handlePositionChange}     
      
      />
    );
  };
export default CompareImage;