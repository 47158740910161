import React, { useState } from "react";
import { CustomInput } from "reactstrap";
import "./CustomCheckbox.css";

function CustomCheckbox(props) {
  const [value, setValue] = useState(props.value || "-");

  const handleChange = () => {
    const options = props.options ||  ["-", "C", "A", "R","NT","N"];
    const currentIndex = options.indexOf(value);
    const nextIndex = (currentIndex + 1) % options.length;
    setValue(options[nextIndex]);
    let event = {};
    event.target = {};
    event.target.value = options[nextIndex];
    event.target.id = props.id;    
    props.onChange(event);
    
  };

  return (
    <div className="custom-checkbox">
      
      <label htmlFor={props.id} className="custom-checkbox-label" onClick={handleChange}>
        {value}
      </label>
    </div>
  );
}

export default CustomCheckbox;
