import GISMap from '../components/GISMap';
import Page from '../components/Page';
import React from 'react';
import { useDevices } from '../service/DeviceService';

import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

const today = new Date();
const lastWeek = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 7,
);

function DashboardPage() {
  const { devices, error, isLoading } = useDevices();

  if (error) return 'An error has occurred.';
  if (isLoading) return 'Loading...';

  return (
    <Page
      className="DashboardPage"
      title="Dashboard"
      breadcrumbs={[{ name: 'Dashboard', active: true }]}
    >
      <div></div>
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <Card inverse className="bg-gradient-primary">
            <CardHeader className="bg-gradient-primary">
              Installed Location
            </CardHeader>
            <CardBody>{devices && <GISMap devices={devices} />}</CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
}

export default DashboardPage;
