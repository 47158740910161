import React from 'react';
import { getColor } from '../utils/colors';
import { Row, Col, Card, CardHeader, CardBody, Spinner } from 'reactstrap';
import AudioView from '../components/AudioView';
import { buildUrl } from '../utils/utils';

class BellPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    let MACAddress = props.MACAddress;
    this.NodeNameLookupTable = {};
    this.Nodes = props.Nodes;

    if(this.Nodes){
      this.Nodes.map(item => {
           if(item.NodeName)
           {
            this.NodeNameLookupTable[item.NodeMacAddress+''] =item.NodeName;
           }else 
           {
            this.NodeNameLookupTable[item.NodeMacAddress+''] =item.NodeMacAddress;
           }
          
          });  
    }
    var self = this;
    fetch(
      buildUrl('/api/audio/data', {
        mac_address: MACAddress,
      }),
      {
        method: 'GET', // or 'PUT'
        mode: 'cors',
        //body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        let audiodata = data.map((val, index) => {
          let path = '';
          if (val.Data.path !== undefined) {
            path = val.Data.path;
          }
          return {
            dateTime: new Date(Date.parse(val.rdateTime)),
            val: 2,
            path: path,
          };
        });

        self.setState({ audiodata: audiodata, audiodatam: data });
      });
  }

  componentDidMount() {}
  render() {
    return (
      <React.Fragment>
        {this.state.audiodatam === undefined && (
          <Spinner
            style={{
              position: 'absolute',
              width: '50px',
              height: '50px',
              top: '100%',
              left: '50%',
            }}
          >
            Loading
          </Spinner>
        )}
        <Row>
          {this.state.audiodatam &&
            this.state.audiodatam.map(
              ({ _id, deviceMac, Data, NodeMacAddress, rdateTime } = {}) => (
                <Col key={_id} md={6} sm={6} xs={12}>
                  {rdateTime && (
                    <div>
                      <b>Date :</b>
                      {new Date(rdateTime).toLocaleString()}
                    </div>
                  )}
                  <div>
                    <b> EIP :</b>
                    {deviceMac}
                  </div>
                  {this.NodeNameLookupTable[NodeMacAddress] && (
                    <div>
                      <b>Name :</b>
                      {this.NodeNameLookupTable[NodeMacAddress]}
                    </div>
                  )}
                  {NodeMacAddress && (
                    <div>
                      <b>Node :</b>
                      {NodeMacAddress}
                    </div>
                  )}


                  <AudioView
                    path={
                      window.location.protocol +
                      '//' +
                      window.location.host +
                      '/' +
                      Data.path
                    }
                  ></AudioView>
                </Col>
              ),
            )}
        </Row>
      </React.Fragment>
    );
  }
}

export default BellPage;
