import React from 'react';
import { createRoot } from 'react-dom/client';
import { SocketProvider } from './SocketIO'

import io from 'socket.io-client';
import App from './App';


const socket = io.connect(process.env.SOCKET_URL);
socket.on('message', msg => console.log(msg));

const container = document.getElementById('root');
const root = createRoot(container);
root.render(  <SocketProvider socket={socket}>
  <App />
</SocketProvider>);
// ReactDOM.render(
//     <SocketProvider socket={socket}>
//     <App />
//   </SocketProvider>  
    
//     , document.getElementById('root'));
