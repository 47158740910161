import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';

import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import AlertPage from './pages/AlertPage';
import AuthModalPage from './pages/AuthModalPage';
import AuthPage from './pages/AuthPage';

import CrossingPage from './pages/CrossingPage';
import BellPage from './pages/BellPage';
import GLMPage from './pages/GLMPage';

// pages
import DashboardPage from './pages/DashboardPage';
import DeveloperPage from './pages/DeveloperPage';

import ImagesPage from './pages/ImagesPage';

import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import DevicesPage from './pages/DevicesPage';
import PrivateLayoutRoute from './components/Layout/PrivateLayoutRoute';
import GLMVideosPage from './pages/GLMVideosPage';
import SLMPage from './pages/SLMPage';
import AboutPage from './pages/AboutPage';
import SummuryPage from './pages/SummuryPage';

import InspectionPageV2 from './pages/InspectionPageV2';
import InspectionListPage from './pages/InspectionsListPage';


const getBasename = () => {
  return `.`;
};

class App extends React.Component {
  render() {
    console.log(getBasename());

    if (window.performance) {
      if (performance.navigation.type == 1) {
       // alert( "This page is reloaded" );
        window.location.href = "/";
        
      } else {
        //alert( "This page is not reloaded");
      }
    }
    return (
      <BrowserRouter>
        <Switch>
          <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={props => <AuthPage {...props} authState={STATE_LOGIN} />}
          />
          <LayoutRoute
            exact
            path="/signup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_SIGNUP} />
            )}
          />
          <LayoutRoute
            exact
            path="/login-modal"
            layout={MainLayout}
            component={AuthModalPage}
          />
          <PrivateLayoutRoute
            exact
            path="/"
            layout={MainLayout}
            component={DashboardPage}
          />
          <PrivateLayoutRoute
            exact
            path="/imagesview"
            layout={MainLayout}
            component={ImagesPage}
          />

          <PrivateLayoutRoute
            exact
            path="/developer"
            layout={MainLayout}
            component={DeveloperPage}
          />

          <PrivateLayoutRoute
            exact
            path="/bells"
            layout={MainLayout}
            component={BellPage}
          />

          <PrivateLayoutRoute
            exact
            path="/crossing"
            layout={MainLayout}
            component={CrossingPage}
          />

          <PrivateLayoutRoute
            exact
            path="/devices"
            layout={MainLayout}
            component={DevicesPage}
          />

          <PrivateLayoutRoute
            exact
            path="/glmvideos"
            layout={MainLayout}
            component={GLMVideosPage}
          />

          <PrivateLayoutRoute
            exact
            path="/glmdevices"
            layout={MainLayout}
            component={GLMPage}
          />
          <PrivateLayoutRoute
            exact
            path="/alerts"
            layout={MainLayout}
            component={AlertPage}
          />

      
          <PrivateLayoutRoute
            exact
            path="/register"
            layout={MainLayout}
            component={AuthPage}
          />
          <PrivateLayoutRoute
            exact
            path="/devices/slm"
            layout={MainLayout}
            component={SLMPage}
          />
          <PrivateLayoutRoute
            exact
            path="/devices/glm"
            layout={MainLayout}
            component={GLMPage}
          />    <PrivateLayoutRoute
          exact
          path="/devices/summury"
          layout={MainLayout}
          component={SummuryPage}
        />    <PrivateLayoutRoute
        exact
        path="/devices/inspection"
        layout={MainLayout}
        component={InspectionPageV2}
      />


<PrivateLayoutRoute
            exact
            path="/inspectionlist"
            layout={MainLayout}
            component={InspectionListPage}
          />
          <PrivateLayoutRoute
            exact
            path="/about"
            layout={MainLayout}
            component={AboutPage}
          />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
