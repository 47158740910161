
import {React,useState} from 'react';
import { socketConnect } from '../SocketIO';
import ImageView from '../components/ImageView';
import { buildUrl } from '../utils/utils';
import CompareImage from '../components/CompareImage';
import useSWR,{ useSWRConfig } from 'swr';

import useSWRMutation from 'swr/mutation'



import
{
  Button,
  Card,
  CardBody,
  CardImg,
  CardImgOverlay,
  CardLink,
  CardText,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import ReactImageAppear from 'react-image-appear';

const imagefetcher = url => fetch(url).then(res => res.json());

async function sendRefImageRequest(url, { arg }) {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(arg),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.json())
}



function useImages(MACAddress)
{

  const url = buildUrl('/api/getimages', {
    mac_address: MACAddress,
  });
  const { data, error, isLoading } = useSWR(url, imagefetcher);

  return {
    imagesData: data,
    isLoading: isLoading,
    isError: error,
    
  };
}


function DrawImages({ _id, deviceMac, imageData, NodeMacAddress,ReferenceImage, rdateTime,nodereflist,NodeName,mutate}) 
{
  let refDateTime="";
  console.log(nodereflist);
   let refimageObj = nodereflist[NodeMacAddress];

   let refImagesource = window.location.protocol + "//" + window.location.host + "/" + imageData.path;
   let newref = window.location.protocol + "//" + window.location.host + "/" + imageData.path;
   if(refimageObj && refimageObj.ReferenceImage){
    refImagesource = refimageObj.ReferenceImage;
    refDateTime = refimageObj.refDateTime;
   }
   
   const { trigger, isMutating } = useSWRMutation('/api/imagereference', sendRefImageRequest, /* options */)
  return (
    <Col key={_id} md={6} sm={6} xs={12}>
      <Card className="text-top">
        <div className="imagecardstyle">
        {refDateTime && (
            <div>
              <b>RefDate :</b>
              {new Date(refDateTime).toLocaleString()}
            </div>
          )}

          {rdateTime && (
            <div>
              <b>Date :</b>
              {new Date(rdateTime).toLocaleString()}
            </div>
          )}
       {refimageObj&& refimageObj.NodeName &&
 <div>
   <b> Name:
   </b> {refimageObj.NodeName}
 </div>
}
          {NodeMacAddress && (
            <div>
              <b>Node :</b>
              {NodeMacAddress}
            </div>
          )}
        </div>
        {/* <ImageView path={window.location.protocol + "//" +window.location.host+"/"+imageData.path} onloaded={this.onloaded}></ImageView> */}
        <CompareImage
        leftImageLabel={refDateTime}
          itemOne={<img
            src={refImagesource}
            style={{
              display: "flex",
              width: "100%",
              height: "60vh",
              transform: "rotate(180deg)",
            }}
            alt="one" />}
          itemTwo={<img
            src={window.location.protocol + "//" + window.location.host + "/" + imageData.path}
            style={{
              display: "flex",
              width: "100%",
              height: "60vh",
              transform: "rotate(180deg)",
              filter: "sepia(0.5)"
            }}
            alt="two" />}

        ></CompareImage>
        <Row>          
          <Button
          disabled={isMutating}
          onClick={async () => {
          try {
           
          const result = await trigger({ ID: deviceMac,NodeMacAddress:NodeMacAddress,imagePath:newref,refDateTime: rdateTime}, /* options */)
          mutate(NodeMacAddress,newref,rdateTime);
        } catch (e) {
          // error handling
        }
      }}> Set As Reference</Button>
        </Row>
      </Card>
    </Col>
  );
}





 function ImagesPage(props) {

  const { mutate } = useSWRConfig();
  console.log(props);
  let nodereflist={};
  
  props.socket.on('imagerefupdate', async function (data) {
    console.log("Done Update" +data);
      
  });
  

  let result = props.Nodes.map((node) => 
  {
    let obj ={
     ReferenceImage:node.ReferenceImage,
     refDateTime:node.refDateTime,
     NodeName:node.NodeName

    };
    nodereflist[node.NodeMacAddress]=obj;
     return nodereflist[node.NodeMacAddress]} );
  
  const [nodesreflist, setReferenceState] = useState(nodereflist);

  const { imagesData, error, isLoading } = useImages(props.MACAddress);

  if (error) return 'An error has occurred.';
  if (isLoading) return 'Loading...';
  if(!imagesData) return 'No images';
  let test=(nodeMac,refimage,refDateTime)=> { 
     mutate("/api/getimages");     
     setReferenceState(prevState => ({
      ...prevState,
      [nodeMac]: {ReferenceImage:refimage,
        refDateTime:refDateTime}
    }));
     

    
  }
  
  return (
    
    <Row>
            {imagesData.map((n)=><DrawImages {...n} nodereflist={nodesreflist} mutate={test}></DrawImages>)
           }
          </Row>

  )
}



export default socketConnect(ImagesPage);
