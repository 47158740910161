import gatearmimg from '../assets/img/gatearmimg.png';
import React from 'react';
import * as moment from 'moment';
import { FaCamera, FaNetworkWired ,FaTrain,FaVideo} from 'react-icons/fa';
import "./blinkstyle.css";

import {
  Card,
  Media,
  Button,
  CardImg,
  CardHeader,
  CardTitle,
  CardText,
  CardGroup,
  CardSubtitle,
  CardBody,
} from 'reactstrap';

class GLMDeviceControl extends React.Component {
  state = {};
  constructor() {
    super();
    this.handleCaptureImage = this.handleCaptureImage.bind(this);
    this.handleStartVideoRecording = this.handleStartVideoRecording.bind(this);
    this.state = {
      TotalDevices: 0,
    };
  }


  /**
  * Starts a video recording on the GLM device with the given ID.
  * Prompts the user to enter the recording length in seconds.
  * Validates the input is a number and stores it in duration.
  * Sends a RecordVideoOnDemand command to the GLM device with the ID,
  * duration, and other required parameters.
  */
  handleStartVideoRecording(id) {
    console.log('Command GLM Capture for ' + id);
    let seconds = prompt("Please enter the length of recording", "60");
    //check seconds if it is proper number and store it in duration varible
    let duration = parseInt(seconds);
    let data = { Command: 'RecordVideoOnDemand', glmid: id, Duration: duration };
    fetch('/api/glmcommands', {
      method: 'PUT', // or 'PUT'
      mode: 'cors',
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(response =>
        console.log('Success (GLM Command):', JSON.stringify(response)),
      )
      .catch(error => console.error('Error (GLM Command):', error));
  }

  handleCaptureImage(id) {
    console.log('Command GLM Capture for ' + id);
    let data = { Command: 'CaptureImage', glmid: id };
    fetch('/api/glmcommands', {
      method: 'PUT', // or 'PUT'
      mode: 'cors',
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(response =>
        console.log('Success (GLM Command):', JSON.stringify(response)),
      )
      .catch(error => console.error('Error (GLM Command):', error));
  }

  handlePingCommand(id) {
    console.log('Command GLM Capture for ' + id);
    let data = { Command: 'Ping', glmid: id };
    fetch('/api/glmcommands', {
      method: 'PUT', // or 'PUT'
      mode: 'cors',
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(response =>
        console.log('Success (GLM Command):', JSON.stringify(response)),
      )
      .catch(error => console.error('Error (GLM Command):', error));
  }

  render()
  {
    return (
      <CardGroup>
        {this.props.glmdevices.map((device, index) =>
        {
          return (
            <Card key={index}>
              <CardHeader className="text-center">
                {device.DeviceName}
                {device?.RecordingVideo?.onDemand ? (
                  <FaVideo className="blink" style={{ fontSize: "2em", color: "red" }} />
                ) : null}
                {device?.RecordingVideo?.onTrain ? (
                  <FaTrain className="blink" style={{ fontSize: "2em", color: "red" }} />
                ) : null}
              </CardHeader>
              <CardImg
                top
                width="50%"
                className="img-fluid"
                src={
                  device.latestimage
                    ? window.location.protocol +
                    "//" +
                    window.location.host +
                    "/" +
                    device.latestimage
                    : gatearmimg
                }
              ></CardImg>
              <CardBody>
                <CardSubtitle>
                  <b>Installation Date:</b> {device.InstallationDate}
                </CardSubtitle>
                <CardSubtitle>
                  <b>Local IP:</b>
                  {device.WIFI_IP}
                </CardSubtitle>
                <CardSubtitle>
                  <b>Private IP:</b> {device.VPN_IP}
                </CardSubtitle>
                <CardSubtitle>
                  <b>Last Updated: </b>
                  {moment(device.UpdatedAt).fromNow()} at{" "}
                  {moment(device.UpdatedAt).format("LLL")}{" "}
                </CardSubtitle>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.handleCaptureImage(device.DeviceId)}
                >
                  <FaCamera> </FaCamera> Capture Image{" "}
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.handlePingCommand(device.DeviceId)}
                >
                  {" "}
                  <FaNetworkWired></FaNetworkWired> Ping{" "}
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.handleStartVideoRecording(device.DeviceId)}
                  disabled={
                    device?.RecordingVideo?.onTrain ||
                    device?.RecordingVideo?.onDemand
                  }
                >
                  {" "}
                  <FaVideo></FaVideo> Record Video{" "}
                </Button>
              </CardBody>
            </Card>
          );
        })}
      </CardGroup>
    );
  }




}

export default GLMDeviceControl;
