var data={ Device_id: 'GATEWAY_SL_001_01',
  mac_address: '13a200418861e3',
  Node_ID: '13a200418fc124',
  Content:
   { PacketID: { Value: 0, Type: 'UInt32' },
     CurrentDateTime: { Value: '', Type: 'String' },
     EventStartTime: { Value: '2019-06-17-17:52:27', Type: 'String' },
     EventEndTime: { Value: '', Type: 'String' },
     TrainPresenceStatus: { Value: false, Type: 'Bool' },
     GatewayID: { Value: 0, Type: 'UInt32' },
     SensorNodeID: { Value: 0, Type: 'UInt8' },
     EventType: { Value: 0, Type: 'UInt8' },
     AcceleroHealth: { Value: false, Type: 'Bool' },
     AcceleroStatus: { Value: 0, Type: 'UInt8' },
     AcceleroMin: { Value: 0, Type: 'Int16' },
     AcceleroMax: { Value: 0, Type: 'Int16' },
     AcceleroAvg: { Value: 0, Type: 'Int16' },
     TimeStart: { Value: 181090575, Type: 'UInt32' },
     TimeEnd: { Value: 181165585, Type: 'UInt32' },
     MaxLux: { Value: 339, Type: 'UInt32' },
     AvgLux: { Value: 321, Type: 'UInt32' },
     AvgRed: { Value: 253, Type: 'UInt32' },
     AvgGreen: { Value: 104, Type: 'UInt32' },
     AvgBlue: { Value: 5, Type: 'UInt32' },
     BlinkCount: { Value: 78, Type: 'UInt16' },
     AnalysisStatus: { Value: 1, Type: 'UInt8' },
     MicroPhoneHealth: { Value: false, Type: 'Bool' },
     MicrophoneStatus: { Value: 0, Type: 'UInt8' },
     MicrophoneMin: { Value: 0, Type: 'Int16' },
     MicrophoneMax: { Value: 0, Type: 'Int16' },
     MicrophoneAvg: { Value: 0, Type: 'Int16' },
     SensorAvgTemp: { Value: 0, Type: 'Int8' } } }

     export default data;