import React from 'react';
import PropTypes from '../utils/propTypes';

import {  
  Table,  
  Row,
  Col
} from 'reactstrap';


export const propTypes = {
    nodedata: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.ID,
      Node_ID: PropTypes.string,
      EventDateTime: PropTypes.string,
      BlinkCount: PropTypes.Number,
      LightStatus: PropTypes.Number,
      Color : PropTypes.Color,
      MaxLux : PropTypes.Number,
      AvgLux  : PropTypes.Number,
      EventDuration  : PropTypes.Number

    })
  ),
};

const NodeDatas = ({ nodedata, ...restProps }) => {
  return (
     
           
                <Row>
                  <Col>
                   
                      <Table  {...restProps}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>SLM Node ID </th>
                            <th>EventDateTime </th>
                            <th>BlinkCount </th>
                            <th>LightStatus</th>
                            
                            <th>MaxLux</th>
                            <th>AvgLux</th>
                            <th>EventDuration(ms)</th>
                          </tr>
                        </thead>
                        <tbody>
                        {nodedata.map(({ id,Node_ID, EventDateTime,BlinkCount, LightStatus,Color,MaxLux,AvgLux,EventDuration} = {}) => (
                            <tr key={id}>
                            <th scope="row">{id}</th>
                            <td>{Node_ID}</td>
                            <td>{EventDateTime}</td>
                            <td>{BlinkCount}</td>
                            <td>{LightStatus?'true':'false'}</td>                            
                            <td>{MaxLux}</td>
                            <td>{AvgLux}</td>
                            <td>{EventDuration}</td>                            
                          </tr>



                        ))}
                        </tbody>
                      </Table>
                  
                  </Col>
                  </Row>
                  
   
  );
};

NodeDatas.propTypes = propTypes;

NodeDatas.defaultProps = {
    nodedata: [],
};

export default NodeDatas;
