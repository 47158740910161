import React, { useState } from 'react';
import PropTypes from '../../utils/propTypes';
import LightNode from '../LightNode';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Col,
  Row,
  Badge,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  CardTitle,
} from 'reactstrap';
import classnames from 'classnames';
import CrossingLightBlinker from '../../components/Widget/CrossingLightBlinker';
import { FaCamera } from 'react-icons/fa';
import NodeDatas, {
  propTypes as NodeDataPropTypes,
} from '../../components/NodeDatas';


import {flashrateCalculation} from '../historyView';


const styles = {
  center: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

const DeviceCard = ({
  title,
  GateWayId,
  NodeId,
  Name,
  MACAddress,
  subtitle,
  Nodes,
  nodedata,
  handleCaptureImage,
  ...restProps
}) => {
  let [activeTab, setActiveTab] = useState('1');
  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }
  return (
    <React.Fragment>
    
            <Row>
              <Col sm="12">
                <Card
                  className="mb-3 justify-content-center "
                  body
                  outline
                  color="primary"
                  {...restProps}
                >
                  <CardHeader>
                    <b>{title} : </b>
                    {GateWayId}
                    <div>
                      <b>{'Device Name'} : </b>
                      {Name}
                    </div>
                    <div>
                      <b>Edge Image Processor : </b>
                      {MACAddress}
                    </div>{' '}
                  </CardHeader>

                  <CardBody>
                    <Row className={styles.center}>
                      {Nodes.map((node, index) => {
                        //console.log(node);
                        if(node.LightSensorData){
                        node.FlashRate=flashrateCalculation(node.LightSensorData);
                        }
                        return (
                          <Col
                            lg="4"
                            md="8"
                            sm="12"
                            xs="12"
                            className="border"
                            style={{ 'text-align': 'center' }}
                          >
                            <div>
                              <h1>
                                <Badge color="light"> {node.BlinkCount}</Badge>
                              </h1>
                              {node.FlashRate && 
                              <div style={{fontSize:"small",marginLeft:"auto",display:"table",backgroundColor:"lightgrey",padding:"2%"}}
        ><div style={{fontWeight:"bold"}}>Flash Rate</div><span>{node.FlashRate} / minute</span>
</div>}
                            </div>
                              
                            {
                              //<LightNode key={index} blinkCount={node.BlinkCount} faulty={!(node.Status==="LIGHT OK")} node={node} index={index} ></LightNode>    }
                            }

                            <CrossingLightBlinker
                              MaxBlinkCount={node.BlinkCount}
                            ></CrossingLightBlinker>
 { node.NodeName && (
                        <div
                                className="text-center"
                              style={{ 'margin-bottom': '12pt' }}
                            >
                                {' '}
                                <h4>
                                <b>
                                  <Badge color="dark" lg >
                                  {node.NodeName}
                                  </Badge>
                                </b>
                                </h4>
                              </div>
 )}
                            <div
                              className="text-center"
                              style={{ 'margin-bottom': '12pt' }}
                            >
                              <div>
                                {' '}
                                <b>
                                  {`Node ${index + 1}`} <span>: </span>{' '}
                                  {node.NodeMacAddress}
                                </b>
                              </div>
                              
                              <Button
                                color="primary"
                                style={{
                                  top: '50%',
                                  left: '50%',
                                  margin: '1%',
                                }}
                                onClick={() => handleCaptureImage(index)}
                              >
                                {' '}
                                <FaCamera></FaCamera> Capture Image{' '}
                              </Button>{' '}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>

                    {
                      //  <NodeDatas nodedata={nodedata} />  }
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
     
   
   
    </React.Fragment>
  );
};

DeviceCard.propTypes = {
  GateWayId: PropTypes.string,
  NodeId: PropTypes.string,
  MACAddress: PropTypes.string,
  nodedata: NodeDataPropTypes.nodedata,
  Nodes: PropTypes.array,
  handleCaptureImage: PropTypes.func.isRequired,
};

DeviceCard.defaultProps = {
  GateWayId: '12345 ',
  NodeId: '2221',
  MACAddress: '',
  title: 'GateWay',
  Nodes: [],
};

export default DeviceCard;
