import React from "react";
import PropTypes from "prop-types";


function CrossingLightSVG({  
    isON,  
  }) {
    
    let color="#550000";
    if(isON)
    {
         color="#ff0000";
    }
    else
    {
       color= "#550000";

    }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="300" height="250">
      <path fill="none" d="M-1 -1H301V251H-1z"></path>
      <g fillRule="nonzero" strokeMiterlimit="4">
        <path
          fill="#000"
          stroke="#242f3d"
          strokeDashoffset="0"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeOpacity="0.519"
          strokeWidth="8.767"
          d="M269.442 126.286a119.473 114.457 0 11-238.945 0 119.473 114.457 0 11238.945 0z"
        ></path>
        <path
          fill="#4d4d4d"
          stroke="#ffeff0"
          strokeLinejoin="round"
          strokeWidth="0.5"
          d="M149.014 35.227c-47.503 0-86.004 32.123-86.004 71.724 0 .33.02.647.025.976h171.958c.005-.329.025-.646.025-.976 0-39.601-38.5-71.724-86.004-71.724h0z"
        ></path>
        <path
          fill={color}
          stroke="#000"
          strokeDashoffset="0"
          strokeLinecap="square"
          strokeWidth="3.471"
          d="M229.314 133.302a79.8 82.08 0 11-159.601 0 79.8 82.08 0 11159.6 0z"
        ></path>
      </g>
    </svg>
  );
}



CrossingLightSVG.propTypes = {
    isON:PropTypes.bool,
   };
   


export default CrossingLightSVG;