import React from "react";
import PropTypes from "prop-types";

const AudioView = ({  
  path,  
}) => {

    

  return (
    <React.Fragment>


 


<audio controls src={path} type="audio/wav"></audio> 
</React.Fragment>)
;
}



AudioView.propTypes = {
   path:PropTypes.string,
  };
  
  export default AudioView;

