import React from 'react';
import { getColor } from '../utils/colors';
import DetectionStatusTable from '../components/DetectionStatusTable';
import { Row, Col, Card, CardHeader, CardBody,Spinner } from 'reactstrap';
// import {  Scatter } from 'react-chartjs-2';
import Page from '../components/Page';


var dateFromObjectId = function (objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};
  


class CrossingPage extends React.Component {

 

  constructor()
  {
  super(); 
  this.state={};
  this.state.Data=[];
  this.state.tabledata =[];
  this.pageSize = 20;
  this.pagesCount =10;
  this.state.currentPage=1;
  this.handlePageClick = this.handlePageClick.bind(this);
  this.handlePreviousClick = this.handlePreviousClick.bind(this);
  this.handleNextClick = this.handleNextClick.bind(this);
 this.colors = ['primary','secondary']
  this.setState({graphData:{}});
  var self = this;
  fetch('/api/getdetectionstatus')
  .then(response => response.json())
  .then(data =>
    {
      
      var seriesdata={};
       if(Array.isArray(data))
       {

        let nodesinfo = Object.values(data[0].Data.Value);
      self.datamap = nodesinfo.map((node,index)=>
      {

        seriesdata[node.NodeMacAddress] = 
        {
          label:  'Node '+(index+1)+' ( '+node.NodeMacAddress+' )',
          backgroundColor: getColor(this.colors[index]),
          borderColor: 'rgba(5,0,0,1)',
          borderWidth: 0.5,      
          data: []       
        
        }
      
      return ;
      });

      let filtereddata =data.filter( (val)=>{ return val.Data.Value.Node_1.BlinkCount>0});

    self.data = filtereddata.map((val,index)=>
    { 
                
       let nodesinfo = Object.values(val.Data.Value);
       let datetime = dateFromObjectId(val._id );  

       //if( nodesinfo[0].BlinkCount>0 && nodesinfo[1].BlinkCount>0){
       self.state.tabledata.push({srno:index+1,'dateTime':datetime,'Node1BlinkCount':nodesinfo[0].BlinkCount,'Node2BlinkCount':nodesinfo[1].BlinkCount}) ;
       
       self.datamap = nodesinfo.map((node,index)=>
       {
         if(seriesdata[node.NodeMacAddress]!==undefined)
         {
          seriesdata[node.NodeMacAddress].data.push(node.BlinkCount);
         }
         else
         {
 
         seriesdata[node.NodeMacAddress] = 
         {
           label: node.NodeMacAddress+'',
           backgroundColor: getColor('primary'),
           //borderColor: getColor('primary'),
           borderColor: 'rgba(5,0,0,1)',
           borderWidth: 2,         
           data: [node.BlinkCount]
         }
        }

      
       return  seriesdata[node.NodeMacAddress];
 
       });
      //}
 
       

       
       self.state.Data.push(  datetime  );


       return  seriesdata[nodesinfo.NodeMacAddress];

     //  testdata = genLineDataFromNode(nodesinfo[0].BlinkCount,nodesinfo[1].BlinkCount);
     

        }
      );

    }
  let timeformat ="mm:HH:";
  let options={
  
  
    scales: {
      xAxes: [{
        display: true,
        type: 'time',
        time: {          
          
          unit: 'hour',
                displayFormats: {
                  
            'hour': 'MM/DD/YYYY:  HH:mm'
          }
        }
      }],
      yAxes: [
        {
           type:'logarithmic',
           stacked: false,
           ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, values) {
                return  value;
            }
          },           
               
            
             display: true,
    }
      ]
  },
  legend:{
    display:true,
    position:'top'
  }
}

this.state.graphoptions= options;



    var testdata={
      labels: self.Data,
      datasets:  Object.values(seriesdata),
      options: {
        scales: {
          xAxis: [{
            display: true,            
            type: 'time'
          
            }
          
          ],
            yAxes: [{
              ticks: {
                  // Include a dollar sign in the ticks
                  callback: function(value, index, values) {
                      return '$' + value;
                  }
              }
          }]
        }
    }
    }
    
    self.pagesCount = Math.round(self.state.tabledata.length/this.pageSize);
    self.setState( {graphData:  testdata});
    self.setState({tabledata: self.state.tabledata});

    
  }
  );

  }



handlePageClick(e, index) 
{
  e.preventDefault();
  
  this.setState({currentPage:index+1})
  
  
}

handleNextClick(e) {
  e.preventDefault();
  this.setState({currentPage: this.state.currentPage+1})

}


handlePreviousClick(e) {
  e.preventDefault();
  this.setState({currentPage: this.state.currentPage-1})
}
  render() {
  return (
    <Page title="Crossings" breadcrumbs={[{ name: 'crossings', active: true }]}>

  
   
{

  
  this.state.graphData!==undefined &&
    <Row>
<DetectionStatusTable detectionsStatus={this.state.tabledata} 
pageSize={this.pageSize}
pagesCount={this.pagesCount}
currentPage={this.state.currentPage - 1}
handlePageClick={this.handlePageClick}
handlePreviousClick={this.handlePreviousClick}
handleNextClick={this.handleNextClick}
></DetectionStatusTable>   
 </Row> 
        
        }
    <Row>
   
{

 ( this.state.graphData===undefined && <Spinner style={{ width: '3rem', height: '3rem',position:"absolute" , top: "50%", left: "50%" }} >Loading</Spinner>)
}{
  this.state.graphData!==undefined &&
         this.state.graphData.datasets.map((val,index)=>
        {

          let testdata=
          {
            labels: this.state.Data,
            datasets:[val],
          }
       
        //  console.log(testdata);
          return (
            <Col  key={index} xl={12} lg={12} md={12}>
            <Card>
              <CardHeader>Flash Counts </CardHeader>
              <CardBody>
          {/* <Scatter  data={testdata} options={this.state.graphoptions} /> */}
          </CardBody>
          </Card>
        </Col>
          )
        })
      }
          
        
         
     


  </Row>
  

    </Page>
  );

          };
};

export default CrossingPage;
