import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { FaAutoprefixer } from 'react-icons/fa';
const { useState, useEffect } = React;
const ImageView = ({ path, onloaded}) => {
  const [loading, setLoading] = useState(true);
  const imageLoaded = () => {
    setLoading(false);
    onloaded(true);
  };

  return (
    <React.Fragment>
      <div style={{ display: loading ? 'block' : 'none' }}>
        <Spinner color="primary" style={{ margin: 'auto', display: 'flex' }}>
          Loading ...
        </Spinner>
      </div>
      <div style={{ display: loading ? 'none' : 'block' }}>
        <img
          width="100%"
          src={path}
          className="rotateimg180"
          alt=""
          onLoad={imageLoaded}
        />{' '}
      </div>
    </React.Fragment>
  );
};

ImageView.propTypes = {
  path: PropTypes.string,
};

export default ImageView;
