import AuthForm, { STATE_LOGIN } from '../components/AuthForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import {userService } from '../service/user.service'

class AuthPage extends React.Component {

  state={};
  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      console.log(this.state);
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };
  
  
  handleResponse = (data)=>
  {
    console.log(data);


  }

  handleLoginclick=(authState,data)=>
  {
    if (authState === STATE_LOGIN) {
      const username=data.get('username');
      const password=data.get('password');
    //  console.log(username);
      //this.props.history.push('/login');
     const user= userService.login(username,password,this.handleResponse);
     user.then((value)=>{
             console.log(user);
             window.analytics.identify('f4ca124298', {
              name: username,
              email: username
            });
             console.log(this.state)
             this.props.history.push('/dashboard');
     }).catch(error=>
      {
    this.setState({error:error});
      console.log(error);


      });
    

    }
     else {
      const username=data.get('username');
      const password=data.get('password');
      //this.props.history.push('/signup');
     // userService.signUp(username,password);
    }

  };

  render() {
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        
        <Col md={6} lg={4}>
          <Card body>
            <AuthForm showLogo={true}
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
              onLoginSignUp={this.handleLoginclick}
              onErrors={this.state.error}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AuthPage;
