import Page from '../components/Page';
import React from 'react';
import { socketConnect } from '../SocketIO';
import ReactPlayer from 'react-player';
import { buildUrl } from '../utils/utils';

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardImgOverlay,
  CardLink,
  CardText,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';



export default class GLMVideosPage extends React.Component {
  state = {
    VideosData: [],
  };
  constructor(props) {
    super(props);

    this.getGLMDevices = this.getGLMDevices.bind(this);
    this.NodeNameLookupTable ={};
    
   
  }

  componentDidUpdate(prevProps) {
 
 }

 async getGLMDevices(MACAddress) {
   var self = this;
   try {
     const response = await fetch(
       buildUrl('/api/getGLMDevices', {
         mac_address: MACAddress,
       }),
       {
         method: 'GET', // or 'PUT'
         mode: 'cors',
         //body: JSON.stringify(data), // data can be `string` or {object}!
         headers: {
           'Content-Type': 'application/json',
         },
       },
     );
     const json = await response.json();
     //console.log(json);

     

     self.setState({ glmdevices: json });

     if(json){
      json.map(item => {
           if(item.DeviceName)
           {
            self.NodeNameLookupTable[item.DeviceId+''] =item.DeviceName;
           }else 
           {
            self.NodeNameLookupTable[item.DeviceId+''] =item.DeviceId;
           }
          
          });  
    }
    
     if (self.props.MacAddress) {
       const url = buildUrl('/api/glm/getVideos', {
         mac_address: self.props.MacAddress,
       });
       
   
         fetch(url)
           .then(response => response.json())
           .then(data => {
             self.setState({ VideosData: data });
             console.log(self.state.VideosData);
           });
       }

   } catch (error) {
     console.log('error', error);
   }
  }
  componentDidMount(props) {
    // console.log(props);
    this.getGLMDevices(this.props.MacAddress);
    this.setState({ MACAddress: this.props.MacAddress });
 
  }
  render() {
    return (
      <Row>
        {this.state.VideosData.map(({ _id, GlmId, Data, rdateTime } = {}) => (
          <Col key={_id} md={6} sm={6} xs={12}>
            <Card className="text-top">
              <div className="imagecardstyle">
                {rdateTime && (
                  <div>
                    <b>Date :</b>
                    {new Date(rdateTime).toLocaleString()}
                  </div>
                )}
                <div>
                  <b> GLM :</b>
                  {this.NodeNameLookupTable[GlmId] && this.NodeNameLookupTable[GlmId]}
                </div>
              </div>
              
                <ReactPlayer
                  width={'100%'}
                  style={{ alignSelf: 'center',marginBottom:2 }}
                  url={window.location.protocol + "//" +window.location.host+"/"+Data.path}
                  controls={true}
                />
            </Card>
          </Col>
        ))}
      </Row>
    );
  }
}

//export default socketConnect(GLMVideosPage);
